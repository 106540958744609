import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import { WebsiteCmsService, WebsiteContent } from './websiteCms.service';

@Injectable()
export class WebsiteBaseComponent implements OnInit, OnDestroy {

  websiteContent: WebsiteContent;
  subscribe: Subscription = undefined;

  constructor(private cms: WebsiteCmsService) {
    this.updateCmsContent(cms.cmsSubject.value);
  }

  ngOnInit() {
    this.subscribe = this.cms.cmsObservable.subscribe(content => {
      this.updateCmsContent(content);
    });
  }

  updateCmsContent(websiteContent: WebsiteContent) {
    this.websiteContent = websiteContent;
  }

  ngOnDestroy() {
    if (!!this.subscribe) {
      this.subscribe.unsubscribe();
    }
  }

}
