import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmComponent } from '../confirm/confirm.component';
import { ShareUserDetailsComponent } from '../share-user-details/share-user-details.component';

@Component({
  selector: 'app-mobile-schedule-tour',
  templateUrl: './mobile-schedule-tour.component.html',
  styleUrls: ['./mobile-schedule-tour.component.scss']
})
export class MobileScheduleTourComponent implements OnInit {
  managerDetail: any;
  sid: string;
  tempDate:any;
  showDialog: boolean = true;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MobileScheduleTourComponent>) { 
    this.sid = this.route.snapshot.queryParamMap.get('sid');
  }

  ngOnInit() {
    localStorage.setItem("propertyMangerDetails", JSON.stringify(this.data));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  shareUser(){
    const dialog = this.dialog.open(ShareUserDetailsComponent);
  }

  tour() {
    this.router.navigate(['/property/search/schedule-tour'], { queryParams: { sid: this.sid } });
    this.onNoClick();
  }
}
