import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder,  NgForm} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToasterService } from 'angular2-toaster';
import { CommonService } from '../../../common/commonService';
import { urlConstant } from '../../../constant/urlConstant';

@Component({
  selector: 'app-search-contactus-kochi',
  templateUrl: './search-contactus-kochi.component.html',
  styleUrls: ['./search-contactus-kochi.component.scss']
})
export class SearchContactusKochiComponent implements OnInit {

  contactUsModel:any = {};
  isSubmitted = false;
  textAreaValue: any;
  shareForm: any;
  
  constructor(
    private commonService: CommonService,
    public dialogRef: MatDialogRef<SearchContactusKochiComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: FormBuilder,
    public toasterService: ToasterService,
  ) { 
    this.textAreaValue =  "Hi, I am interested in Property " + this.data.propertyName
    this.contactUsModel ={
      message: this.textAreaValue
    }
  }

  ngOnInit() { 
  }
    
  onNoClick() {
    this.dialogRef.close();
  }
  
  sendContactUsDetail(contactForm:NgForm){ 
    if (contactForm.invalid) {
      this.isSubmitted = true;
      return;
    }
    this.commonService.showLoading();
    this.isSubmitted = false;
    this.commonService.post(urlConstant.User.searchKochiContact, this.contactUsModel).subscribe((res) => {
      if (!!res) {
        this.toasterService.pop('success', 'Success', res.message);
      } 
      contactForm.reset();
      this.dialogRef.close();    
      this.commonService.hideLoading();  
    });
  }
}
