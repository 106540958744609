import { Injectable } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {LegalDocComponent} from './legal-doc.component';

@Injectable({
  providedIn: 'root'
})
export class LegalDocService {

  constructor(public dialog: MatDialog) {

  }

  openDialog(doc): MatDialogRef<any, any> {
    const dialogRef = this.dialog.open(
      LegalDocComponent,
      {data: doc}
    );
    return dialogRef;
  }
}
