import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { CommonService } from '../../../common/commonService';
import { urlConstant } from '../../../constant/urlConstant';
import { time } from '../time';

@Component({
  selector: 'app-schedule-tour',
  templateUrl: './schedule-tour.component.html',
  styleUrls: ['./schedule-tour.component.scss']
})

export class ScheduleTourComponent implements OnInit {
  
  userInfo:any=[];
  previousUrl: string;
  currentDate: Date = new Date();
  defaut: Date = new Date();
  public selectedWeekDates = [];
  time: time;
  selectedaTime: time;
  selectedaDate: any;
  dataTime: any;
  dataDate: any;
  currentProperties: any;



  times: time[] = [{ id: 9, startTimeValue: 9, endTimeValue: 10, endTime: 10, endType: "AM", type: "AM", checked: false }, { id: 10, startTimeValue: 10, endTimeValue: 11, endTime: 11, endType: "AM", type: "AM", checked: false },
  { id: 11, startTimeValue: 11, endTimeValue: 12, endTime: 12, endType: "PM", type: "AM", checked: false },
  { id: 12, startTimeValue: 12, endTimeValue: 13, endTime: 1, endType: "PM", type: "PM", checked: false },
  { id: 1, startTimeValue: 13, endTimeValue: 14, endTime: 2, endType: "PM", type: "PM", checked: false },
  { id: 2, startTimeValue: 14, endTimeValue: 15, endTime: 3, endType: "PM", type: "PM", checked: false },
  { id: 3, startTimeValue: 15, endTimeValue: 16, endTime: 4, endType: "PM", type: "PM", checked: false },
  { id: 4, startTimeValue: 16, endTimeValue: 17, endTime: 5, endType: "PM", type: "PM", checked: false },
  { id: 5, startTimeValue: 17, endTimeValue: 18, endTime: 6, endType: "PM", type: "PM", checked: false },
  { id: 6, startTimeValue: 18, endTimeValue: 19, endTime: 7, endType: "PM", type: "PM", checked: false },
  ]

  public selectedWeek: Date = new Date();
  appDate: Date;
  appTime: number;
  data: boolean = true;
  datanew: boolean = true;
  sid: string;
  radioSelected: any;
  radioSel: any;
  radioSelectedString: string;
  radioTimeSelected: any;
  tempDate: Date;
  AppointmentDetails: any;
  pmDetails: any;

  constructor(
    private elRef: ElementRef,
    private route: ActivatedRoute,
    public router: Router,
    public _CommonService: CommonService,
    public toasterService: ToasterService) { }

  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem('userData'));

    this.pmDetails = JSON.parse(localStorage.getItem('propertyMangerDetails'));

    let dte: Date = new Date(this.selectedWeek);
    this.selectedWeekDates.push({ date: this.currentDate, checked: false });
    for (let i = 1; i < 10; i++) {
      this.selectedWeekDates.push({ date: new Date(dte.setDate(dte.getDate() + 1)), checked: false });

    }
    this.currentProperties = JSON.parse(localStorage.getItem('saveProperties'));
    this.dataTime = JSON.parse(localStorage.getItem('saveTime'));
    this.dataDate = JSON.parse(localStorage.getItem('saveDate'));
    if (this.tempDate) {
      let d2 = new Date(this.tempDate)
      for (var i in this.selectedWeekDates) {
       
        if (this.selectedWeekDates[i].date.toISOString().split('T')[0] === d2.toISOString().split('T')[0]) {
          this.selectedWeekDates[i].checked = true;
          this.dataDate = this.selectedWeekDates[i]
          break; //Stop this loop, we found it!
        }

      }
      this.selectedDate(this.dataDate);
    }
    this.sid = this.route.snapshot.queryParamMap.get('sid');
  }


  selectedDate(quality) {
    this.selectedaDate = quality;
   
    this.appDate = quality.date;
    if (this.appDate !== null && this.appTime !== undefined) {
      this.data = false;
    }
  }

  selectedTime(quality: time) {
    this.selectedaTime = quality
 
    this.appTime = quality.id
    if (this.appTime !== null && this.appDate !== undefined) {
      this.data = false;
    }
  }

  activeButton
  showPhase(event) {
    this.activeButton = event;
  }

  contact() {
   
    localStorage.removeItem('tempDate');
    localStorage.setItem('saveTime', JSON.stringify(this.selectedaTime));
    localStorage.setItem('saveDate', JSON.stringify(this.selectedaDate));
    this.router.navigate(['/property/search/success'], { queryParams: { sid: this.sid } });
    this.saveAppoiment();
  }
  getSelecteditem() {
    this.radioSel = this.radioSelected;
    this.radioSelectedString = JSON.stringify(this.radioSel);
  }
  onItemChange(items) {
   
    this.radioSelected = items;
    this.dataDate = this.radioSelected;
  }

  onTimeChange(item) {
    this.radioTimeSelected = item;
    this.dataTime = this.radioTimeSelected;
  }


  saveAppoiment() {
    this.AppointmentDetails = {
      date: this.dataDate.date, startTime: this.dataTime.startTimeValue,
      startType: this.dataTime.type, endTime: this.dataTime.endTimeValue, endType: this.dataTime.endType,
      shareId: this.sid, propertyId: this.currentProperties.propertyId, clintName: localStorage.getItem('clintName'),
      shareBy: this.pmDetails.userId,
      shareUserId: this.userInfo.userId
    };

    this._CommonService.showLoading();
    this._CommonService.post(urlConstant.Property.addAppointment, this.AppointmentDetails)
      .subscribe((res) => {
        if (!!res) {
          this.toasterService.pop("success", "Success", res.message);
        } else {
          this.toasterService.pop("error", "Error", res.message);
        }
        this._CommonService.hideLoading();
        this.router.navigate(['/property/search/success'], { queryParams: { sid: this.sid } });
      },
        (error) => {
          if (error != null) {
            this.toasterService.pop("error", "Error", error.message);
          }
          this._CommonService.hideLoading();
        });
  }

  public getPreviousUrl() {
    return this.router.navigate(['/property/search'], { queryParams: { sid: this.sid } });
  }
}
