import { Component, Input, OnInit } from '@angular/core';
import {BaseComponent} from '../../../common/base.component';
import {CmsService} from '../../../common/cms.service';

@Component({
  selector: 'app-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss']
})
export class CustomHeaderComponent  extends BaseComponent {
  @Input() title = 'Shared Property';

  constructor(cms: CmsService) {
    super(cms);
  }

}
