import {Injectable} from '@angular/core';
import {urlConstant} from '../constant/urlConstant';
import {CommonService} from './commonService';
import {BehaviorSubject} from 'rxjs';
import {Observable} from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  public cmsSubject: BehaviorSubject<CmsContent>;
  public cmsObservable: Observable<CmsContent>;

  constructor(private commonService: CommonService) {
    this.cmsSubject = new BehaviorSubject(new CmsContent());
    this.cmsObservable = this.cmsSubject.asObservable();
  }

  getLabels() {
    // if (window.location.hostname === 'easerent.com') {
    //   return;
    // }
    this.commonService.get(`${urlConstant.CMS.getLabels}`).subscribe((res: any[]) => {
      if (!!res) {
        this.update(res);
      }
    }, (error) => {
    });
  }

  update(res) {
    const cms: CmsContent = this.cmsSubject.value;
    res.filter(pc => pc.page === 'common').map(pc =>
      pc.contents.map((pcc: any) => {
        switch (pcc.identity) {
          case 'brand_logo':
            cms.brandLogo = pcc.url;
            document.querySelectorAll('.navbar-brand>img').forEach((node: any) => {
              node.src = pcc.url;
            });
            break;
          case 'brand_name':
            cms.brandName = pcc.text;
            break;
          case 'colors':
            this.parseColor(pcc.text, cms);
            break;
        }
      })
    );
    this.cmsSubject.next(JSON.parse(JSON.stringify(cms)));
  }

  parseColor(colors: String, cms: CmsContent) {
    if (!colors) {
      return;
    }
    const colorArr: String[] = colors.split(';');
    colorArr.map(colorStr => {
      const arr = colorStr.split(':');
      if (arr.length === 2) {
        switch (arr[0]) {
          case 'primary':
            cms.sideBarBgColor = arr[1];
            break;
        }
      }
    });
  }
}

export class CmsContent {
  brandName = '';
  brandLogo = '';
  sideBarBgColor = '#428960';
}
