import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { CommonService } from '../../common/commonService';
import { WebsiteBaseComponent } from '../../common/websitebase.component';
import { WebsiteCmsService } from '../../common/websiteCms.service';
import { dataConstant } from '../../constant/dataConstant';
import { urlConstant } from '../../constant/urlConstant';

@Component({
  selector: 'app-display-property',
  templateUrl: './display-property.component.html',
  styleUrls: ['./display-property.component.scss'],
  providers: [NgbCarouselConfig] 
})
export class DisplayPropertyComponent extends WebsiteBaseComponent {
  propertyTypeList = dataConstant.propertyType;
  
 // @ViewChild("carousel") 
  public carousel : NgbCarousel
  images = [700, 800, 807].map((n) => `https://picsum.photos/id/${n}/900/500`);
  
  isMenuCollapsed = true;
  properties: any = [];
  partnerId: string;
  cities: any = [];
  statuses: any = [];
  bhkList: any = [
    { id: 'Select BHK' }
  ];
  selectedCity: string = '';
  selectedTypeStatus: string = '';
  searchText: string = '';
  selectedBHK = null;
  selectedLocality: string = '';
  localities: any = [];
  constructor(
    config: NgbCarouselConfig,
    private commonService: CommonService,
    cms: WebsiteCmsService,
    public websiteService: WebsiteCmsService,
    public _CommonService: CommonService,
    public toasterService: ToasterService, 
    public router: Router, 
    private route: ActivatedRoute
    ) {
    super(cms);
    this.websiteService.getService();
    this.websiteService.getProperty();
    config.interval = 2000;
    config.keyboard = true;
    config.pauseOnHover = true;
   }
 
  ngOnInit() {
    console.log(this.propertyTypeList)
    super.ngOnInit();
    this.getProperty();
  }
  
  clearFilter() {
    this.selectedCity = '';
    this.selectedLocality = '';
    this.selectedTypeStatus = '';
    this.searchText = '';
    this.selectedBHK = this.bhkList[0];
    this.filter('', '', '', '', this.bhkList[0]);
  }
  
  textChange(searchText) {
    // this.filter(this.selectedCity, this.selectedTypeStatus, this.selectedBHK);
    this.filter(this.selectedCity, this.selectedLocality, this.selectedTypeStatus, searchText, this.selectedBHK);
  }
  
  getProperty() {
    this._CommonService.showLoading();
    this._CommonService.get<any>(urlConstant.Property.getPropertyByPartnerId + '?partnerId=' + this.partnerId).subscribe(res => {
    // this._CommonService.get<any>(urlConstant.Property.getPropertyByPartnerId + '?partnerId=').subscribe(res => {
      if (res.Status === '200') {
        this.properties = res.data.properties;    
        // this.email = res.data.shareProperties.email;    
        console.log(this.properties)         
        // console.log(this.shareProperties.mobile)         
        // console.log(this.shareProperties.email)        
        if (res.data.bhkList) {
          res.data.bhkList.map(bhk => {
            this.bhkList.push({ id: bhk });
          });
        }
        this.cities = [];
        this.localities = [];
        this.statuses = [];
        for (let p of this.properties) {
          if (this.cities.indexOf(p.city) == -1) {
            this.cities.push(p.city);
          }

          if (p.locality) {
            this.localities.push(p.locality);
            console.log(this.localities)
          }

          if (p.type && p.type.id) {
            let obj = this.statuses.find(o => o.id == p.type.id);
            if (!obj) {
              if(p.type.id != '2816') {
                this.statuses.push(p.type);
              }
            }
          }
        }

        console.log("cities ====>>>>", this.cities);
        
      } else {
        this.toasterService.pop('error', 'Error', res.message);
        }
        this._CommonService.hideLoading();
      }, (error) => {
        // this.loading = false;
        if (error != null) {
          this.toasterService.pop('error', 'Error', error.message);
        }
      this._CommonService.hideLoading();
    });
  }

  onChangeCity(selectedCity) {
    this.selectedCity = selectedCity;
    this.filter(selectedCity, this.selectedLocality, this.selectedTypeStatus, this.searchText, this.selectedBHK);
  }

  onChangeLocality(selectedLocality) {
    this.selectedLocality = selectedLocality;
    this.filter(this.selectedCity, selectedLocality, this.selectedTypeStatus, this.searchText, this.selectedBHK);
  }

  onChangeStasus(selectedTypeStatus) {
    this.selectedTypeStatus = selectedTypeStatus;
    this.filter(this.selectedCity, this.selectedLocality, selectedTypeStatus, this.searchText, this.selectedBHK);
  }

  onChangeBHK(selectedBHK) {
    this.selectedBHK = selectedBHK;
    this.filter(this.selectedCity, this.selectedLocality, this.selectedTypeStatus, this.searchText, selectedBHK);
  }

  filter(
    selectedCity: String,
    selectedLocality: String,
    selectedTypeStatus: String,
    searchText: String,
    selectedBHK: any
  ) {
    // this.properties = this.propertyDataBackup;
    this.properties = this.properties.filter(el => {
      if (!!selectedTypeStatus) {
        return !!el.type && selectedTypeStatus === el.type.id;
      }
      return true;
    }).filter(el => {
      if (!!selectedCity) {
        return selectedCity === el.city;
      }
      return true;
    }).filter(el => {
      if (!!selectedLocality) {
        return selectedLocality === el.locality;
      }
      return true;
    }).filter(el => {
      if (searchText) {
        return el.propertyName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
      }
      return true;
    }).filter(el => {
      if (selectedBHK && selectedBHK.id !== 'Select BHK') {
        return !!el.bhk && el.bhk === selectedBHK.id;
      }
      return true;
    });
  }

 
  // onChangeStasus(selectedTypeStatus) {
  //   this.selectedTypeStatus = selectedTypeStatus;
  //   //this.filter(this.selectedCity, selectedTypeStatus, this.searchText, this.selectedBHK);
  // }
  scroll(){
    window.scrollTo(0, 0);
    
  }
  
}

