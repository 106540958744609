import {Injectable} from '@angular/core';
import {DocViewerComponent} from './doc-viewer.component';
import {MatDialog, MatDialogRef} from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class DocViewerService {

  constructor(public dialog: MatDialog) {

  }

  openDialog(doc): MatDialogRef<any, any> {
    const dialogRef = this.dialog.open(
      DocViewerComponent,
      {
        data: doc,
        height: '90%',
        width: '80%'
      },
    );
    return dialogRef;
  }
}
