import {Injectable} from '@angular/core';
import {urlConstant} from '../../constant/urlConstant';
import {CommonService} from '../../common/commonService';
import {HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {MatDialog, MatDialogRef} from '@angular/material';
import {MultiFileUploadComponent} from './multi-file-upload.component';
import { NoteComponent } from './note/note.component';

@Injectable({
  providedIn: 'root'
})
export class MultiFileUploadService {

  constructor(
    public dialog: MatDialog,
  ) {
  }

  openDialog(doc): MatDialogRef<any, any> {
    const dialogRef = this.dialog.open(
      MultiFileUploadComponent,
      {
        maxHeight: '90vh',
        data: doc
      }
    );
  
    return dialogRef;
  }

  openNoteDialog(doc): MatDialogRef<any, any> {
    const dialogRef = this.dialog.open(
      NoteComponent,
      {data: {
        note: doc
      }}
    );
    return dialogRef;
  }

  
}
