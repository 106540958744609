import { Component, OnInit } from '@angular/core';
import { messageConstant } from '../../constant/messageConstant';
import { dataConstant } from '../../constant/dataConstant';
import { CommonService } from '../../common/commonService';
import { LoginService } from './login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { isPassword, isValidEmail } from '../../shared/ngbd-modal-content/validators/custom.validator';
import { Globals } from '../../globals';
import { urlConstant } from '../../constant/urlConstant';
import { ToasterService } from 'angular2-toaster';
import {BaseComponent} from '../../common/base.component';
import {CmsService} from '../../common/cms.service';
import {LegalDocService} from '../../shared/legal-doc/legal-doc.service';

@Component({
  selector: 'app-dashboard',
  styleUrls: ['./login.component.scss'],
  templateUrl: 'login.component.html'
})
export class LoginComponent extends BaseComponent {
  showResentVerificationLink;
  loginForm: FormGroup;
  invitationId: any;
  userDetail: any;
  loginUser: any;
  routingList: any[];
  constructor(
    public _CommonService: CommonService,
    public toasterService: ToasterService,
    public formBuilder: FormBuilder,
    cms: CmsService,
    public router: Router,
    public legalDocService: LegalDocService,
    private route: ActivatedRoute,
  ) {
    super(cms);
    this.createForm();
    this.route.queryParams.subscribe(params => {

      this.invitationId = params['invitationId'];

    });
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.invitationId) {
      this.getUserDetailByInvitationId();
    }
  }
  openDoc(title, doc) {
    this.legalDocService.openDialog({title: title, doc: doc});
  }
  createForm(): void {
    this.loginForm = this.formBuilder.group({
      'email': new FormControl('', [Validators.required, isValidEmail]),
      'password': new FormControl('', [Validators.required]),
      'isClicked': new FormControl(false),
      'isSubmited': new FormControl(false),
    });
  }

  get loginFormIsClicked(): FormControl {
    return this.loginForm.get('isClicked') as FormControl;
  }

  get loginFormIsSubmmited(): FormControl {
    return this.loginForm.get('isSubmited') as FormControl;
  }

  loginFormSubmit() {
    this.loginFormIsClicked.setValue(true);
    this.showResentVerificationLink = false;

    if (this.loginForm.invalid && this.loginFormIsSubmmited.value === false) {
      return;
    } else {
      this.loginFormIsSubmmited.setValue(true);
      this._CommonService.showLoading();

      this._CommonService.post(urlConstant.Auth.Login, this.loginForm.value).subscribe((res) => {
        if (!!res.data && !!res.data.showResentVerificationLink) {
          this.showResentVerificationLink = res.data.showResentVerificationLink;
        }
        if (!!res && res['Status'] === '200') {
          localStorage.setItem('token', res['accessToken']);
          localStorage.setItem('user', JSON.stringify(res.data.UserDetails));
          localStorage.setItem('role', JSON.stringify(res.data.UserDetails.roles));
          this.toasterService.pop('success', 'Success', res.Message);

         this.loginUser = JSON.parse(localStorage.getItem('user'));
          this.landingPage(this.loginUser);
         // this.router.navigate(['/admin/dashboard']);
          // if (res.data.UserDetails.roleId === 444) {
          //   this.router.navigate(['/admin/partner/users']);
          // } else if (res.data.UserDetails.roleId === 555) {
          //   this.router.navigate(['/admin/service']);
          // }else if (res.data.UserDetails.roleId === 100) {
          //   this.router.navigate(['/admin/user-access-control/user-role']);
          // } else if(res.data.UserDetails.roleId === 222) {
          //   this.router.navigate(['/admin/properties/list']);
          // }
          // else {
          //   this.router.navigate(['/admin/properties/list']);
          // }
        } else {
          this.toasterService.pop('error', 'Error', res.message);
        }
      }, (error) => {
        if (error != null) {
          this.toasterService.pop('error', 'Error', error.message);
        }
      }).add(() => {
        this.loginFormIsClicked.setValue(false);
        this.loginFormIsSubmmited.setValue(false);
        this._CommonService.hideLoading();
      });
    }
  }

  landingPage(loginUser) {
    this._CommonService.get<any>(urlConstant.Admin.getLandingPage +`?partnerId=`+ loginUser.partnerId + `&userRoleId=` +loginUser.roleId).subscribe((res:any) => {
      if(res.Status ==='200') {
        this.routingList = res.data['urlList'];
        if(this.routingList.length > 0) {
          this.routingList.map(val => {
            if(val.userRoleId === loginUser.roleId) {
              this.router.navigate([val.pageUrl]);
            }
          })
        }
      }
      else if(res.Status === '400') {
        if (loginUser.roleId === 444) {
          this.router.navigate(['/admin/partner/users']);
        } else if (loginUser.roleId === 555) {
          this.router.navigate(['/admin/service']);
        }else if (loginUser.roleId === 100) {
          this.router.navigate(['/admin/user-access-control/user-role']);
        } else if(loginUser.roleId === 222 || loginUser.roleId === 111 || loginUser.roleId === 333) {
          this.router.navigate(['/admin/properties/list']);
        }else {
          this.router.navigate(['/admin/dashboard']);
        }
      }
    })
  }

  isFormSubmittedAndError(controlName: string, errorName: string = '', notError: Array<string> = new Array()): any {
    return Globals.isFormSubmittedAndError(this.loginForm, this.loginForm.get('isClicked').value, controlName, errorName, notError);
  }

  resentVerificationLink() {
    this.showResentVerificationLink = false;
    this._CommonService.showLoading();
    this._CommonService.get<any>(urlConstant.Auth.ResendVerificationLink + '?email=' + this.loginForm.value.email).subscribe(res => {
      if (res.Status === '200') {
        this.toasterService.pop('success', 'Success', res.message);
      } else {
        this.toasterService.pop('error', 'Error', res.message);
      }
     this._CommonService.hideLoading();
    }, (error) => {
      if (error != null) {
        this.toasterService.pop('error', 'Error', error.message);
      }
     this._CommonService.hideLoading();
    });
  }

  getUserDetailByInvitationId() {
   this._CommonService.showLoading();
    // this._CommonService.get<any>(urlConstant.Property.getPropertyUserInvitationByInvitationId + '?invitationId=' + this.invitationId).subscribe(res => {
    //   if (res.Status === '200') {
    //     this.userDetail = res.data.PropertyUserInvitation;
    //     this.setFormValue();
    //   } else {
    //     this.toasterService.pop('error', 'Error', res.message);
    //   }
    //  this._CommonService.hideLoading();
    // }, (error) => {
    //   if (error != null) {
    //     this.toasterService.pop('error', 'Error', error.message);
    //   }
    //  this._CommonService.hideLoading();
    // });
    this._CommonService.get<any>(urlConstant.Auth.getInvitedUser + '?userId=' + this.invitationId).subscribe((res:any) => {
      if(res.Status === '200') {
        this.setFormValue(res.data.User);
        this._CommonService.hideLoading();
      }else {
        this.toasterService.pop('error', 'Error', res.message);
      }
     }, (error) => {
      if (error != null) {
        this.toasterService.pop('error', 'Error', error.message);
      }
     this._CommonService.hideLoading();
    })
  }

  setFormValue(userDetail) {
    this.loginForm.controls['email'].setValue(userDetail.email);
    this.loginForm.controls['password'].setValue(userDetail.password);
  }
}
