import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { JwtInterceptor } from './common/JwtIntercepter';
import {  HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {AuthGuard} from './common/auth.guard';
import { NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BsModalService, ModalModule} from 'ngx-bootstrap/modal';
import {DocViewerModule} from './shared/doc-viewer/doc-viewer.module';
//import {DefaultLayoutComponent1} from './default-layout.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { LoginComponent } from './views/login/login.component';
import { LoginService } from './views/login/login.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTableModule } from 'angular2-datatable';
import { NgbdModalContentComponent } from './shared/ngbd-modal-content/ngbd-modal-content.component';
import { NgbDateCustomParserFormatter } from './common/dateformat';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import {ConfirmDialogComponent, SignupComponent} from './views/signup/signup.component';
import { ToasterModule } from 'angular2-toaster';
import { TakePhotoModalComponent } from './shared/ngbd-modal-content/custom-components/take-photo-modal/take-photo-modal.component';
import { WebcamModule } from 'ngx-webcam';
import { AccountAtivationComponent } from './views/account-ativation/account-ativation.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material';
import {LegalDocModule} from './shared/legal-doc/legal-doc.module';
import { PropertySearchComponent } from './views/property-search/property-search.component';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {MultiFileUploadModule} from './shared/multi-file-upload/multi-file-upload.module';
import { ScheduleTourComponent } from './views/property-search/schedule-tour/schedule-tour.component';
import { PropertyContactComponent } from './views/property-search/property-contact/property-contact.component';
import { ScheduleSuccessComponent } from './views/property-search/schedule-success/schedule-success.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ApartmentComponent } from './pages/apartment/apartment.component';
import { BuildersComponent } from './pages/builders/builders.component';
import { BuildingComponent } from './pages/building/building.component';
import { BuyingPropertyComponent } from './pages/buying-property/buying-property.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';

import { SellPropertyComponent } from './pages/sell-property/sell-property.component';
import { RentalPropertyComponent } from './pages/rental-property/rental-property.component';
import { HousesComponent } from './pages/houses/houses.component';
import { HouseComponent } from './pages/house/house.component';
import { HeadermainComponent } from './pages/headermain/headermain.component';
import { FootermainComponent } from './pages/footermain/footermain.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';

import { ConfirmComponent } from './views/property-search/confirm/confirm.component';
import { ContactSuccessComponent } from './views/property-search/contact-success/contact-success.component';
import { MainLandingPageComponent } from './pages/main-landing-page/main-landing-page.component';
import { DisplayPropertyComponent } from './pages/display-property/display-property.component';
import { DatePipe } from '@angular/common';
import { PropertyWebsiteComponent } from './views/property-search/property-website/property-website.component';
import { CustomHeadersComponent } from './views/property-search/custom-headers/custom-headers.component';
import { MobileHeaderComponent } from './views/property-search/mobile-header/mobile-header.component';
import { MaterialModule } from './material.module';
import { MobileDetailsComponent } from './views/property-search/mobile-details/mobile-details.component';
import { MobileScheduleTourComponent } from './views/property-search/mobile-schedule-tour/mobile-schedule-tour.component';
import { MobileHeadersComponent } from './views/property-search/mobile-headers/mobile-headers.component';
import { ShareUserDetailsComponent } from './views/property-search/share-user-details/share-user-details.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { NpnSliderModule } from 'npn-slider';
import { SearchContactusKochiComponent } from './views/property-search/search-contactus-kochi/search-contactus-kochi.component';
import { ServiceListComponent } from './views/service-request/service-list/service-list.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CustomHeaderModule } from './views/property-search/custom-header/custom-header.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import { DataMigrationComponent } from './views/data-migration/data-migration.component';
import { CsvDownload } from './common/csv-download';
import { CommonPaymentComponent } from './shared/common-payment/common-payment/common-payment.component';
import { ImageShowModalComponent } from './shared/image-slider/image-show-modal/image-show-modal.component';


@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AppAsideModule,
        AppBreadcrumbModule.forRoot(),
        AppFooterModule,
        AppHeaderModule,
        AppSidebarModule,
        PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        HttpClientModule,
        ToasterModule.forRoot(),
        NgxSpinnerModule,
        HttpClientModule,
        FormsModule,
        DataTableModule,
        NgbModule.forRoot(),
        ReactiveFormsModule,
        DocViewerModule,
        LegalDocModule,
        WebcamModule,
        MatDialogModule,
        CarouselModule,
        ModalModule,
        MultiFileUploadModule,
        MatTabsModule,
        MaterialModule,
        NgxPaginationModule,
        NgxUsefulSwiperModule,
        LightboxModule,
        NpnSliderModule,
        CustomHeaderModule,
        NgxDaterangepickerMd.forRoot({
          separator: ' - ',
          applyLabel: 'Okay',
      }),
      NgxDaterangepickerMd,
      NgxSliderModule,
      BsDatepickerModule.forRoot(),
      MatDatepickerModule,
      MatNativeDateModule,
      MatTooltipModule,
    ],
  declarations: [
    AppComponent,
    APP_CONTAINERS,
    LoginComponent,
    NgbdModalContentComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SignupComponent,
    TakePhotoModalComponent,
    AccountAtivationComponent,
    LandingPageComponent,
    ConfirmDialogComponent,
    PropertySearchComponent,
    ScheduleTourComponent,
    PropertyContactComponent,
    ScheduleSuccessComponent,
    AboutUsComponent,
    ApartmentComponent,
    BuildersComponent,
    BuildingComponent,
    BuyingPropertyComponent,
    ContactUsComponent,
    FootermainComponent,
    HeadermainComponent,
    HouseComponent,
    HousesComponent,
    RentalPropertyComponent,
    SellPropertyComponent,
    MainLandingPageComponent,
    DisplayPropertyComponent,
    ConfirmComponent,
    ContactSuccessComponent,
    PropertyWebsiteComponent,
    CustomHeadersComponent,
    MobileHeaderComponent,
    MobileDetailsComponent,
    MobileScheduleTourComponent,
    MobileHeadersComponent,
    ShareUserDetailsComponent,
    SearchContactusKochiComponent,
    DataMigrationComponent,
    CommonPaymentComponent,
    ImageShowModalComponent
  ],
  entryComponents: [NgbdModalContentComponent, 
    TakePhotoModalComponent, ConfirmDialogComponent, 
    ConfirmComponent,MobileScheduleTourComponent,
     ShareUserDetailsComponent,SearchContactusKochiComponent,
     CommonPaymentComponent,ImageShowModalComponent],
  providers: [
    AuthGuard, LoginService, BsModalService,DatePipe, CsvDownload, 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter
    },
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {panelClass: 'mat-dialog-override'}}
  ],
  bootstrap: [ AppComponent ]
  
})
export class AppModule { }
