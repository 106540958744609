import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CommonService } from '../../../common/commonService';
import { urlConstant } from '../../../constant/urlConstant';
import Swal from 'sweetalert2';
import { MultiFileUploadService } from '../../multi-file-upload/multi-file-upload.service';
import { ImageShowModalComponent } from '../../image-slider/image-show-modal/image-show-modal.component';
import { ToasterService } from 'angular2-toaster';
import { paidAmountValidator } from '../../../common/custom.validator';

@Component({
  selector: 'app-common-payment',
  templateUrl: './common-payment.component.html',
  styleUrls: ['./common-payment.component.scss']
})
export class CommonPaymentComponent implements OnInit {
  payment: any;
  paymentForm: FormGroup
  paymentList: any[] = [];
  recepintList: any[] = [];
  recepintFromList: any[] = [];
  billPaymentDetailsBean: any;
  propertyStatusList: any[] = [];
  paymentStatusId: any;
  isSubmitted: boolean = false;
  markPaymentList: any[] = [];
  partialPaymentAmount: number = 0;
  loginUser: any;
  attachmentList: any;
  invoice: any;
  rent:any;
  propertyId: any;
  paymentDetailsBean: any = {};
  paymentSection: any;
  identityId: any;
  docType: any;
  imageDocIds: any[] = [];
  maxDate: string;
  showMessage: boolean = false;
  allImages: Array<string> = ['png', 'jpg', 'jpeg', 'gif', 'tiff', 'bpg', 'docx', 'doc']
  document: any;
  futureDateError: boolean = false;
  totalAmount: any;
  propertyUserList: any[] = [];
  selectedPay: any[] = [];
  selectedBillPayTo: string = null;
  constructor(
    public dialogRef: MatDialogRef<CommonPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private commonServcie: CommonService,
    public multiFileUploadService: MultiFileUploadService,
    public MatDialog: MatDialog,
    public toaterService: ToasterService
  ) {
    if (!!this.data) {
      if (!!this.data.billDetails) {
        this.payment = this.data.billDetails;
        this.identityId = this.payment.billId;
        this.propertyId = this.payment.propertyId
        this.recepintList = this.data.recepintList;
        this.docType = this.data.docType;
        this.totalAmount = this.payment.amount !== null ? this.payment.amount : 0.0;
        this.recepintFromList = this.recepintList.filter(ele => ele.id !== Number(this.payment.billPayToId)).map(val => val);
        if (this.payment !== undefined && this.payment.paymentList.length > 0) {
            this.payment.paymentList.filter(ele => String(ele.paymentStatus.description).toLowerCase() === String("Partial Payment").toLowerCase()).map((val: any) => {
              this.partialPaymentAmount = Number(Number(this.partialPaymentAmount) + Number(val.paymentAmount));
              this.markPaymentList.push(val);
            });
        } else {
          this.markPaymentList = [];
        }
      }
      if (!!this.data.invoice) {
        this.invoice = this.data.invoice;
        this.identityId = this.invoice.id;
        this.propertyId = this.invoice.propertyId;
        this.paymentSection = this.invoice.paymentSection;
        this.recepintList = this.data.recepintList;
        this.recepintFromList = this.recepintList.filter(ele => ele.id !== Number(this.invoice.payToId)).map(val => val);
        this.docType = this.invoice.docType;
        this.totalAmount = this.invoice.grandTotal !== null ? this.invoice.grandTotal : 0.0;
        if (this.invoice !== undefined && this.invoice.paymentList.length > 0) {
          this.invoice.paymentList.filter(ele => String(ele.paymentStatus.description).toLowerCase() === String("Partial Payment").toLowerCase()).map((val: any) => {
            this.partialPaymentAmount = Number(Number(this.partialPaymentAmount) + Number(val.paymentAmount));
            this.markPaymentList.push(val);
          });

        } else {
          this.markPaymentList = [];
        }
      }
      if (!!this.data.rentPaymentDetial) {
         this.rent = this.data.rentPaymentDetial;
         this.identityId = this.rent.scheduledId;
         this.propertyId = this.rent.propertyId
         this.recepintList = this.data.recepintList;
         this.docType = this.data.docType;
        if(this.rent.amount !==null){
          if(this.rent.symbol === 'K'){
            this.totalAmount = this.rent.amount * 1000
          }else if(this.rent.symbol === 'Lacs'){
            this.totalAmount = this.rent.amount * 100000
          }else if(this.rent.symbol === 'Cr'){
            this.totalAmount = this.rent.amount * 10000000
          }
        }else{
          this.totalAmount =  0.0;
        }
         
        // this.recepintFromList = this.recepintList.filter(ele => ele.id !== Number(this.payment.billPayToId)).map(val => val);
        if (this.rent !== undefined && this.rent.paymentList!== null && this.rent.paymentList!== undefined && this.rent.paymentList.length > 0) {
            this.rent.paymentList.filter(ele => String(ele.paymentStatus.description).toLowerCase() === String("Partial Payment").toLowerCase()).map((val: any) => {
              this.partialPaymentAmount = Number(Number(this.partialPaymentAmount) + Number(val.paymentAmount));
              this.markPaymentList.push(val);
            });
        } else {
          this.markPaymentList = [];
        }
      }
    }
  }

  ngOnInit() {
    this.loginUser = this.commonServcie.getLoggedInUser();
    this.intializeForm();
    this.setTodayAsMaxDate();
    this.paymentModeList();
    this.getPaymentStatus();
    this.getPropertyUserList();
  }
  getPropertyUserList() {
    this.commonServcie.get<any>(urlConstant.Common.getPropertyUserList + '?propertyId=' + this.propertyId).subscribe((res: any) => {
      if (res.Status === '200') {
        this.propertyUserList = res.data.propertyUserList
      }
    })
  }

  setTodayAsMaxDate() {
    const today = new Date().toISOString().split('T')[0];
    this.maxDate = today;
  }

  getPaymentStatus() {
    this.commonServcie.get<any>(urlConstant.PropertyStatus.StatusGet + 'PAYMENT-STATUS').subscribe((res: any) => {
      if (res.Status === '200') {
        this.propertyStatusList = res.data.Status
      }
    })
  }
  paymentModeList() {
    this.commonServcie.get<any>(urlConstant.PropertyStatus.getPaymentType).subscribe((res: any) => {
      if (res.Status === '200') {
        this.paymentList = res.data.payemntTypeList;
      }
    })
  }

  intializeForm() {
    this.paymentForm = this.fb.group({
      paymentAmount: new FormControl('', [Validators.required, Validators.pattern("^[0-9]+(\\.[0-9]+)?$")
        , paidAmountValidator(Number(Number(this.totalAmount).toFixed(2)), Number(Number(this.partialPaymentAmount).toFixed(2)))]),
      paymentDate: new FormControl(''),
      comment: new FormControl(''),
      paymentMode: new FormControl(''),
      paymentFrom: new FormControl(''),
      paymentTo: new FormControl(''),
    })
  }

  get formControls() {
    return this.paymentForm.controls;
  }

  onNoClick() {
    this.dialogRef.close({ event: 'close' })
  }

  markPayment() {
    var tempImageDocList = [];
    if(!!this.data.billDetails){
      tempImageDocList = this.data.billDetails.imageDocIds
    }
    if(!!this.data.rentPaymentDetial){
      tempImageDocList = this.data.rentPaymentDetial.imageDocIds
    }
    this.imageDocIds = [];
    if (this.attachmentList !== undefined && this.attachmentList !== null && this.attachmentList.length > 0) {
      this.attachmentList.map((val: any) => {
        this.imageDocIds.push(val.documentId);
      });
    }
    if (this.paymentForm.invalid) {
      this.paymentForm.markAllAsTouched();
      return;
    }
    if (this.futureDateError === true) {
      this.isSubmitted = true;
      return;
    }
    Swal.fire({
      title: 'Payment Conformation',
      html: 'Are you sure  want to mark payment ?',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCloseButton: true
    }).then(result => {
      if (result.isConfirmed) {
        this.paymentDetailsBean = {
          comment: this.paymentForm.value.comment,
          paymentAmount: this.paymentForm.value.paymentAmount,
          paymentDate: this.paymentForm.value.paymentDate,
          paymentFromId: Number(this.paymentForm.value.paymentFrom),
          paymentToId: Number(this.paymentForm.value.paymentTo),
          paymentModeId: Number(this.paymentForm.value.paymentMode),
          paymentStatusId: this.paymentStatusId,
          propertyId: this.propertyId,
          paymentSection: this.paymentSection,
          identityId: this.identityId,
          imageDocIds: this.imageDocIds !== null && this.imageDocIds !== undefined && this.imageDocIds.length > 0 ? tempImageDocList.concat(this.imageDocIds) : tempImageDocList,
        }
        
        this.commonServcie.showLoading();
        this.commonServcie.post(urlConstant.Common.setUpMarkPayments, this.paymentDetailsBean).subscribe((res: any) => {
          if (res.Status === '200') {
            this.toaterService.pop('success', 'Success', res.message);
            this.dialogRef.close();
            
          }
          this.commonServcie.hideLoading();
        })
      }
    })
  }

  getIds(value: any, data: any[]): number {
    return Number(data.filter((ele: any) => String(ele.paramValue).toLowerCase() === String(value).toLowerCase()).map(val => val.id));
  }

  openMultiDialog() {
    this.multiFileUploadService.openDialog({
      propertyId: this.propertyId,
      userId: this.loginUser.userId,
      comment: 'File',
      docType: this.docType,
    }).afterClosed().subscribe((result: any) => {
      this.attachmentList = !!result.data ? result.data : [];
      if (this.attachmentList.length > 0) {
        this.attachmentList.map((val: any) => {
          this.imageDocIds.push(val.documentId);
        });
      }
    })
  }

  showLigthBox(attachmentList, i) {
    let list = [];
    list = attachmentList;
    const dialogRef = this.MatDialog.open(ImageShowModalComponent, {
      width: '800px',
      data: {
        attachment: list,
        index: i
      }
    });
    dialogRef.afterClosed().subscribe(result => { });
  }

  isRolePresent(user: any, roleNameToCheck: string): boolean {
    return user.roles.some(role => role.roleName === roleNameToCheck);
  }

  showPopUpFrom() {
    this.selectedBillPayTo = null;
    let recList = [];
    recList = this.recepintList.filter((ele: any) => String(ele.paramValue).toLowerCase() !== String("Other").toLowerCase()).map(val => val);
    recList.filter((ele: any) => Number(ele.id) === Number(this.paymentForm.value.paymentFrom)).map(val => this.selectedBillPayTo = val.paramValue);
    if (this.propertyUserList.filter((user: any) => user.roleName.toLowerCase() === this.selectedBillPayTo.toLowerCase()).map(val => val).length === 0) {
      Swal.fire({
        title: 'Email',
        html: 'Please add ' + this.selectedBillPayTo + ' to send an Email!',
        icon: 'error',
        showCloseButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Ok',
      })
    }
    this.showPopUp();
  }

  showPopUpTo() {
    this.selectedBillPayTo = null;
    let recList = [];
    recList = this.recepintList.filter((ele: any) => String(ele.paramValue).toLowerCase() !== String("Other").toLowerCase()).map(val => val);
    recList.filter((ele: any) => Number(ele.id) === Number(this.paymentForm.value.paymentTo)).map(val => this.selectedBillPayTo = val.paramValue);
    if (this.propertyUserList.filter((user: any) => user.roleName.toLowerCase() === this.selectedBillPayTo.toLowerCase()).map(val => val).length === 0) {
      Swal.fire({
        title: 'Email',
        html: 'Please add ' + this.selectedBillPayTo + ' to send an Email!',
        icon: 'error',
        showCloseButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Ok',
      })
    }
    this.showPopUp();
  }

  showPopUp() {
    if (Number(this.paymentForm.value.paymentFrom) === Number(this.paymentForm.value.paymentTo)) {
      Swal.fire({
        html: "Payment From And Payment To cannot Be Same.",
        showConfirmButton: true,
        confirmButtonText: "OK",
      });
      this.paymentForm.value.paymentFrom = "";
      this.paymentForm.value.paymentTo = "";
    }
  }

  isImage(item) {
    return !!this.allImages.find(t => item.endsWith(t))
  }

  deleteImage(image: any) {
    Swal.fire({
      icon: 'warning',
      title: 'Delete Confirmation!',
      html: 'Are you sure you want to delete image of bill name ?.',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCloseButton: true
    }).then(result => {
      if (result.isConfirmed) {
        this.commonServcie.delete(urlConstant.BillUtility.deleteDocumnetById, image.documentId).subscribe((res: any) => {
          if (res.Status === '200') {
            this.document = res.data.doc;
            this.toaterService.pop('success', 'Success', res.message);
            let index = this.attachmentList.findIndex(ind => {
              if (ind.documentId === this.document.documentId) {
                return ind;
              }
            })

            if (index !== -1) {
              this.attachmentList.splice(index, 1)
            }
            this.commonServcie.hideLoading();
          } else {
            if (res.Status === '500') {
              this.toaterService.pop('error', 'Error', res.message);
              this.commonServcie.hideLoading();
            } else {

            }
          }
        })
      }
    })
  }

  onDateInput(event: any) {
    const selectedDate = new Date(event.target.value);
    const currentDate = new Date();

    // Compare the selected date with the current date
    if (selectedDate > currentDate) {
      this.futureDateError = true;
      // You can reset the input value or take other actions here
      // For example, resetting the input value:
      // event.target.value = '';
    } else {
      this.futureDateError = false;
    }
  }

  isPdf(item): boolean {
    let index = Number(String(item).lastIndexOf("."));
    let extension = String(item).substring(index + 1, String(item).length);
    return extension.toLowerCase() === String('pdf').toLowerCase() ? true : false;
  }

  isDoc(item) {
    let index = Number(String(item).lastIndexOf("."));
    let extension = String(item).substring(index + 1, item.length);
    return extension.toLowerCase() === String('doc').toLowerCase() || extension.toLowerCase() === String('docx').toLowerCase() ? true : false;
  }

  showValue() {
    console.log("dd", this.paymentForm.value.paymentMode)
  }

  formatIndianNumerationWithDecimals(number: number): string {
    return number !== null && number !== undefined ?number.toLocaleString('en-IN', { maximumFractionDigits: 2 }) : "0" ;
  }
}
