import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../common/base.component';
import { CmsService } from '../../common/cms.service';

@Component({
  selector: 'app-headermain',
  templateUrl: './headermain.component.html',
  styleUrls: ['./headermain.component.scss']
})
export class HeadermainComponent extends BaseComponent {
  isMenuCollapsed: any;

  constructor(
    cms: CmsService
  ) { 
    super(cms);
  }

  ngOnInit() {
  }

}
