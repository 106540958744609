interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: NavData[] = [
  {
    name: 'Dashboard',
    icon: 'icon-speedometer',
    children: [
      {
        name: 'Property',
        url: '/admin/dashboard',
        icon: 'icon-arrow-right',
      },
      {
        name: 'Financial',
        url: '/admin/dashboard/view-all/financial-dashboard',
        icon: 'icon-arrow-right'
      },
      {
        name: 'Lead',
        url: '/admin/dashboard/view-all/lead-management-dashboard',
        icon: 'icon-arrow-right',
      },
      {
        name: 'Task',
        url: '/admin/dashboard/view-all/task-reminder',
        icon: 'icon-arrow-right',
      },
    ],
  },
  {
    name: 'Properties',
    url: '/admin/properties',
    icon: 'icon-home',
  },
  {
    name: 'Rent Payment',
    url: '/admin/rentPayment',
    icon: 'icon-note',
  },
  // {
  //   name: 'Move-in / Move-Out',
  //   url: '/admin/moveInOut',
  //   icon: 'icon-directions',
  // },
  {
    name: 'Bills / Taxes',
    url: '/admin/billsUtilities',
    icon: 'icon-energy',
  },
  {
    name: 'Invoice',
    url: '/admin/invoice',
    icon: 'icon-doc',
  },
  // {
  //   name: 'Refer a Friend',
  //   url: '/admin/referral',
  //   icon: 'icon-people',
  // },
  {
    name: 'Documents',
    url: '/admin/documents',
    icon: 'icon-doc',
  },
  {
    name: 'Service Request',
    url: '/admin/service/list',
    icon: 'icon-doc',
  },
  // {
  //   name: 'Tenants',
  //   url: '/admin/tenants',
  //   icon: 'icon-user-following',
  // },
  {
    name: 'My Vendor Team',
    url: '/admin/service/vendors',
    icon: 'icon-doc',
  },

  {
    name: 'Lead Management',
    //url: '/admin/inquiry/home',
    icon: 'icon-people',
    children: [
      {
        name: 'By Lead',
        url: '/admin/inquiry/home',
        icon: 'icon-arrow-right',
      },
      {
        name: 'By Property',
        url: '/admin/inquiry/property-lead-management',
        icon: 'icon-arrow-right',
      }
    ]
  },
  {
    name: 'Inspection',
    url: '/admin/app-inspection/landing',
    icon: 'icon-doc',
  },
  {
    name: 'Inspection Quote',
    url: '/admin/app-inspection/quote',
    icon: 'icon-doc',
  },
  {
    name: 'Customers',
    icon: 'icon-people',
    children: [
      {
        name: 'Tenants',
        url: '/admin/intake/tenants',
        icon: 'icon-arrow-right',
      },
      {
        name: 'Owners',
        url: '/admin/intake/owners',
        icon: 'icon-arrow-right',
      },
    ]
  },
  {
    name: 'Report',
    url: '/admin/report',
    icon: 'icon-doc',
  },
  // {
  //   name: 'Tenant Information',
  //   url: '/admin/tenant-information/home',
  //   icon: 'icon-doc',
  // }
];

export const navItemsOwner: NavData[] = [
  {
    name: 'Properties',
    url: '/admin/properties',
    icon: 'icon-home',
  },
  {
    name: 'Rent Payment',
    url: '/admin/rentPayment',
    icon: 'icon-note',
  },
  // {
  //   name: 'Move-in / Move-Out',
  //   url: '/admin/moveInOut',
  //   icon: 'icon-directions',
  // },
  {
    name: 'Bills / Taxes',
    url: '/admin/billsUtilities',
    icon: 'icon-energy',
  },
  {
    name: 'Invoice',
    url: '/admin/invoice',
    icon: 'icon-doc',
  },
  // {
  //   name: 'Refer a Friend',
  //   url: '/admin/referral',
  //   icon: 'icon-people',
  // },
  {
    name: 'Documents',
    url: '/admin/documents',
    icon: 'icon-doc',
  },
  {
    name: 'Service Request',
    url: '/admin/service/list',
    icon: 'icon-doc',
  },
  {
    name: 'Report',
    url: '/admin/report',
    icon: 'icon-doc',
  },
  // {
  //   name: 'Tenants',
  //   url: '/admin/tenants',
  //   icon: 'icon-user-following',
  // },
  // {
  //   name: 'My Vendor Team',
  //   url: '/admin/service/vendors',
  //   icon: 'icon-doc',
  // },
  // {
  //   name: 'Lead Management',
  //   url: '/admin/inquiry/home',
  //   icon: 'icon-doc',
  // }
];

export const navItemsTenants: NavData[] = [
  // {
  //   name: 'Dashboard',
  //   url: '/admin/dashboard',
  //   icon: 'icon-speedometer',
  //
  // },
  {
    name: 'Properties',
    url: '/admin/properties',
    icon: 'icon-home',
  },
  {
    name: 'Rent Payment',
    url: '/admin/rentPayment',
    icon: 'icon-note',
  },
  // {
  //   name: 'Move-in / Move-Out',
  //   url: '/admin/moveInOut',
  //   icon: 'icon-directions',
  // },
  {
    name: 'Bills / Taxes',
    url: '/admin/billsUtilities',
    icon: 'icon-energy',
  },
  {
    name: 'Invoice',
    url: '/admin/invoice',
    icon: 'icon-doc',
  },
  // {
  //   name: 'Refer a Friend',
  //   url: '/admin/referral',
  //   icon: 'icon-people',
  // },
  {
    name: 'Documents',
    url: '/admin/documents',
    icon: 'icon-doc',
  },
  {
    name: 'Service Request',
    url: '/admin/service/list',
    icon: 'icon-doc',
  },
  {
    name: 'Report',
    url: '/admin/report',
    icon: 'icon-doc',
  },
];

export const navItemsVendor: NavData[] = [
  {
    name: 'Service Request',
    url: '/admin/service/list',
    icon: 'icon-doc',
  }
];

export const navItemsSuperAdmin: NavData [] =[
  {
    name: 'Users Access Control',
    icon: 'icon-user',
    children: [
      {
        name: 'Menu Master',
        url: '/admin/user-access-control/menu-master',
        icon: 'icon-arrow-right',
      },
      {
        name: 'Sub Menu Master',
        url: '/admin/user-access-control/sub-menu-master',
        icon: 'icon-arrow-right',
      },
      {
        name: 'User Role Access',
        url: '/admin/user-access-control/user-role',
        icon: 'icon-arrow-right',
      }
    ]
  },
  {
    name: 'Data Migration',
    url: '/admin/data-migration',
    icon: 'icon-user',
  },
]

export const navItemsPartnerAdmin: NavData[] = [
  {
    name: 'Dashboard',
    //url: '/admin/dashboard',
    icon: 'icon-speedometer',
    children: [
      {
        name: 'Property',
        url: '/admin/dashboard',
        icon: 'icon-arrow-right',
      },
      {
        name: 'Task',
        url: '/admin/dashboard/view-all/task-reminder/admin-task',
        icon: 'icon-arrow-right',
      },
    ]
  },
  {
    name: 'Properties',
    url: '/admin/properties',
    icon: 'icon-home',
  },
  {
    name: 'Users(PM)',
    url: '/admin/partner/users',
    icon: 'icon-user',
  },
  {
    name: 'Account Detail',
    url: '/admin/partner/bank-detail',
    icon: 'icon-doc',
  },
  {
    name: 'Rent Payment',
    url: '/admin/rentPayment',
    icon: 'icon-note',
  },
  {
    name: 'Bills / Taxes',
    url: '/admin/billsUtilities',
    icon: 'icon-energy',
  },
  {
    name: 'Invoice',
    url: '/admin/invoice',
    icon: 'icon-doc',
  },
  {
    name: 'Inspection',
    icon: 'icon-people',
    children: [
      {
        name: 'Inspection',
        url: '/admin/app-inspection/landing',
        icon: 'icon-arrow-right',
      },
      {
        name: 'Inspection Quote',
        url: '/admin/app-inspection/quote',
        icon: 'icon-arrow-right',
      },
      {
        name: 'Inspection Template',
        url: '/admin/app-inspection/templates',
        icon: 'icon-arrow-right',
      },
      {
        name: 'Inspection Rule',
        url: '/admin/app-inspection/inspectionRule',
        icon: 'icon-arrow-right',
      },
    ]
  },
  {
    name: 'Task Reminder Rule',
    url: '/admin/task/task-reminder-rule',
    icon: 'icon-doc',
  },
  {
    name: 'Documents',
    url: '/admin/documents',
    icon: 'icon-doc',
  },
  {
    name: 'Customers',
    icon: 'icon-people',
    children: [
      {
        name: 'Tenants',
        url: '/admin/intake/tenants',
        icon: 'icon-arrow-right',
      },
      {
        name: 'Owners',
        url: '/admin/intake/owners',
        icon: 'icon-arrow-right',
      },
    ]
  },
  {
    name: 'Service Request',
    url: '/admin/service/list',
    icon: 'icon-doc',
  },
  {
    name: 'Service Description',
    url: '/admin/service/setting/service-description',
    icon: 'icon-doc',
  },
  {
    name: 'Lead Management',
    icon: 'icon-people',
    children: [
      {
        name: 'Assigned Leads',
        url: '/admin/inquiry/admin-lead-assign',
        icon: 'icon-arrow-right'
      },
      {
        name: 'By Lead',
        url: '/admin/inquiry/home',
        icon: 'icon-arrow-right',
      },
      {
        name: 'By Property',
        url: '/admin/inquiry/property-lead-management',
        icon: 'icon-arrow-right',
      }
    ],
  },
  {
    name: 'Report',
    url: '/admin/report',
    icon: 'icon-doc',
  },
  // {
  //   name: 'Tenant Information',
  //   url: '/admin/tenant-information/home',
  //   icon: 'icon-doc',
  // }
];
