import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MultiFileUploadComponent} from './multi-file-upload.component';
import {BrowserModule} from '@angular/platform-browser';
import {MatDialogModule} from '@angular/material';
import { NoteComponent } from './note/note.component';

@NgModule({
  declarations: [
    MultiFileUploadComponent,
    NoteComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MatDialogModule,
  ],
  exports: [
    MultiFileUploadComponent,
    NoteComponent
  ],
  entryComponents: [
    MultiFileUploadComponent,
    NoteComponent
  ],
})
export class MultiFileUploadModule {
}
