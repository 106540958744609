import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { filter } from 'rxjs/operators';
import { CommonService } from '../../../common/commonService';
import { urlConstant } from '../../../constant/urlConstant';
import { RentPaymentService } from '../../../services/rent-payment.service';

@Component({
  selector: 'app-property-contact',
  templateUrl: './property-contact.component.html',
  styleUrls: ['./property-contact.component.scss']
})
export class PropertyContactComponent implements OnInit {
  //next = false;
  isSubmitted = false;
  contactUsModel: any = {};
  sid: string;
  constructor(
    public _CommonService: CommonService,
    public toasterService: ToasterService,
    public router: Router,
    private route: ActivatedRoute,
    public rentPaymentService: RentPaymentService) { }

  public getPreviousUrl() {
    return this.router.navigate(['/property/search'], { queryParams: { sid: this.sid } });
  }

  ngOnInit() {
    this.sid = this.route.snapshot.queryParamMap.get('sid');
  }

  SendContactUsDetail(contactForm: NgForm) {
    if (contactForm.invalid) {
      this.isSubmitted = true;
      return;
    }
    this.isSubmitted = false;
    this._CommonService.showLoading();
    this._CommonService.post(urlConstant.Property.addShareProperty, this.contactUsModel)
      .subscribe((res) => {
        if (!!res) {
          this.toasterService.pop('success', 'Success', res.message);
        } else {
          this.toasterService.pop("error", "Error", res.message);
        }
        contactForm.reset();
        this._CommonService.hideLoading();
        this.nextPage();
      }, (error) => {
        if (error != null) {
          this.toasterService.pop("error", "Error", error.message);
        }
        this._CommonService.hideLoading();
      });
  }

  nextPage() {
    this.router.navigate(['/property/search/contactSuccess'], { queryParams: { sid: this.sid } });
  }
}
