import { NgForm } from '@angular/forms';
import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../common/commonService';
import { Router } from '@angular/router';
import { urlConstant } from '../constant/urlConstant';
import { ToasterService } from 'angular2-toaster';
import {DocViewerService} from '../shared/doc-viewer/doc-viewer.service';
import {BaseComponent} from '../common/base.component';
import {CmsService} from '../common/cms.service';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent extends BaseComponent implements AfterViewInit {

  public currentActive = 'home';
  pdfSrc = location.origin + '/assets/EaseRent.pdf';
  mobileNav = false;
  public pageOffset: any = {};
  public loginInfor: any;
  contactUsModel: any = {};
  @ViewChild('home', { static: false }) homeElement: ElementRef;
  @ViewChild('aboutUs', { static: false }) aboutUsElement: ElementRef;
  @ViewChild('howItsWork', { static: false }) howItsWorkElement: ElementRef;
  @ViewChild('pricing', { static: false }) pricingElement: ElementRef;
  @ViewChild('faq', { static: false }) faqElement: ElementRef;
  @ViewChild('contactUs', { static: false }) contactUsElement: ElementRef;
  @ViewChild('privacyPolicy', { static: false }) privacyPolicyElement: ElementRef;
  @ViewChild('termsServices', { static: false }) termsServicesElement: ElementRef;
  isSubmitted = false;
  constructor(public _CommonService: CommonService,
              private docViewerService: DocViewerService,
              cms: CmsService,
    public router: Router, public toasterService: ToasterService) {
    super(cms);
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit() {
    super.ngOnInit();
    // this.loadScript();
    this.loginInfor = this._CommonService.getLoggedInUser();
    if (this.loginInfor) {
      // this.router.navigate(['/admin/dashboard']);
      if (this.loginInfor.roleId === 444) {
        this.router.navigate(['/admin/partner/users']);
      } else if (this.loginInfor.roleId === 555) {
        this.router.navigate(['/admin/service/list']);
      } else {
        this.router.navigate(['/admin/properties/list']);
      }
    }
  }

  ngAfterViewInit(): void {
    this.pageOffset = {
      home: this.homeElement.nativeElement.offsetTop,
      aboutUs: this.aboutUsElement.nativeElement.offsetTop,
      howItsWork: this.howItsWorkElement.nativeElement.offsetTop,
      pricing: this.pricingElement.nativeElement.offsetTop,
      faq: this.faqElement.nativeElement.offsetTop,
      contactUs: this.contactUsElement.nativeElement.offsetTop,
      privacyPolicy: this.privacyPolicyElement.nativeElement.offsetTop,
      termsServices: this.termsServicesElement.nativeElement.offsetTop,
    };
  }

  @HostListener('window:scroll', ['$event'])
  checkOffsetTop() {
    // console.log(window.pageYOffset, this.pageOffset); // this will console log our scroll position

    if (window.pageYOffset <= this.pageOffset['home']) {
      this.currentActive = 'home';
    } else if (window.pageYOffset >= this.pageOffset['aboutUs'] && window.pageYOffset <= this.pageOffset['howItsWork']) {
      this.currentActive = 'aboutUs';
    } else if (window.pageYOffset >= this.pageOffset['howItsWork'] && window.pageYOffset <= this.pageOffset['pricing']) {
      this.currentActive = 'howItsWork';
    } else if (window.pageYOffset >= this.pageOffset['pricing'] && window.pageYOffset <= this.pageOffset['contactUs']) {
      this.currentActive = 'pricing';
    } else if (window.pageYOffset >= this.pageOffset['contactUs'] && window.pageYOffset <= this.pageOffset['privacyPolicy']) {
      this.currentActive = 'contactUs';
    } else if (window.pageYOffset >= this.pageOffset['privacyPolicy'] && window.pageYOffset <= this.pageOffset['termsServices']) {
      this.currentActive = 'privacyPolicy';
    } else if (window.pageYOffset >= this.pageOffset['termsServices']) {
      this.currentActive = 'termsServices';
    }
  }

  public loadScript() {
    const body = <HTMLDivElement>document.body;

    const scripts = [
      'assets/js/jquery-2.1.0.min.js',
      'assets/js/popper.js',
      'assets/js/bootstrap.min.js',
      'assets/js/scrollreveal.min.js',
      'assets/js/waypoints.min.js',
      'assets/js/jquery.counterup.min.js',
      'assets/js/imgfix.min.js',
      'assets/js/mixitup.js',
      'assets/js/accordions.js',
    ];

    for (const key in scripts) {
      if (Object.prototype.hasOwnProperty.call(scripts, key)) {
        new Promise((resolve, reject) => {
          const scriptSrc = scripts[key];
          const script = document.createElement('script');
          script.innerHTML = '';
          script.src = scriptSrc;
          script.async = true;
          script.defer = true;

          script.onload = () => {
            resolve('Loaded');
          };
          script.onerror = (error: any) => {
            resolve('Error');
          };

          document.getElementsByTagName('head')[0].appendChild(script);
        });
      }
    }
  }

  scrollToElement($element: HTMLElement): void {
    console.log($element);
    this.mobileNav = false;
    $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  SendContactUsDetail(contactForm: NgForm) {
    if (contactForm.invalid) {
      this.isSubmitted = true;
      return;
    }

    this.isSubmitted = false;
      this._CommonService.showLoading();
      this._CommonService.post(urlConstant.Auth.addContactUs, this.contactUsModel).subscribe((res) => {
        if (!!res) {
          this.toasterService.pop('success', 'Success', res.message);
        } else {
          this.toasterService.pop('error', 'Error', res.message);
        }
        contactForm.reset();
        this._CommonService.hideLoading();
      }, (error) => {
        if (error != null) {
          this.toasterService.pop('error', 'Error', error.message);
        }
        this._CommonService.hideLoading();
      });


  }
  clickMobileNav() {
    this.mobileNav = !this.mobileNav;
  }
}
