import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { VideoExt } from '../../../constant/dataConstant';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-show-modal',
  templateUrl: './image-show-modal.component.html',
  styleUrls: ['./image-show-modal.component.scss']
})
export class ImageShowModalComponent implements OnInit {

  attachment:any[]=[];
  activeId;
  allImages: Array<string> = ['png', 'jpg', 'jpeg', 'gif', 'tiff', 'bpg','docx', 'doc']
  fileName: string = "";
  is_fileName: boolean = false;

  constructor(
    public dialog: MatDialogRef<ImageShowModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {
    this.attachment = data.attachment;
    this.activeId = '' + (data.index || 0);
    if(!!data.is_fileName) {
      this.is_fileName = true;
    }
  }

  ngOnInit() {
  }

  isVideo(path: string) {
    const pathLower = path.toLowerCase();
    return !!VideoExt.find(ext => pathLower.lastIndexOf(ext) === (pathLower.length - ext.length));
  }

  isImage(item){
    return !!this.allImages.find(t => item.endsWith(t))
  }
}
