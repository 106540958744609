import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../../common/base.component';
import { CmsService } from '../../../common/cms.service';

@Component({
  selector: 'app-mobile-headers',
  templateUrl: './mobile-headers.component.html',
  styleUrls: ['./mobile-headers.component.scss']
})
export class MobileHeadersComponent extends BaseComponent {
  sid: any;

  constructor(cms: CmsService,
    public router: Router,
    public route:ActivatedRoute) {
    super(cms);
    this.sid = this.route.snapshot.queryParamMap.get('sid');
  }

  ngOnInit() {
  }

  getPreviousPage() {
    this.router.navigate(['/property/search'], { queryParams: { sid: this.sid } });
  }
}