import { Component, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavData, navItems, navItemsOwner, navItemsPartnerAdmin, navItemsSuperAdmin, navItemsTenants, navItemsVendor } from '../../_nav';
import { Router } from '@angular/router';
import { CommonService } from '../../common/commonService';
import { BaseComponent } from '../../common/base.component';
import { CmsService } from '../../common/cms.service';
import { urlConstant } from '../../constant/urlConstant';


// interface NavAttributes {
//   [propName: string]: any;
// }
// interface NavWrapper {
//   attributes: NavAttributes;
//   element: string;
// }
// interface NavBadge {
//   text: string;
//   variant: string;
// }
// interface NavLabel {
//   class?: string;
//   variant: string;
// }

// export interface NavData {
//   name?: string;
//   url?: string;
//   icon?: string;
//   badge?: NavBadge;
//   title?: boolean;
//   children?: NavData[];
//   variant?: string;
//   attributes?: NavAttributes;
//   divider?: boolean;
//   class?: string;
//   label?: NavLabel;
//   wrapper?: NavWrapper;
// }



@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent extends BaseComponent {
  public navItems = [];

  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  loginUser = this._commonService.getLoggedInUser();
  userShortName = null;
  public username = null;
  role = null;
  subscription;
  navItemsList: any[] = [];
  unAssignedList: any[] = [];
  navItemsDynamic: NavData[] = [];



  constructor(
    cms: CmsService,
    public router: Router,
    public _commonService: CommonService,
    private render: Renderer2, @Inject(DOCUMENT) _document?: any,) {
    super(cms);
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });

    if (!this.loginUser) {
      this.router.navigate(['/login']);
    }

   
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setUserDetail();
    this.subscription = this._commonService.userUpdate.subscribe(x => {
      this.loginUser = this._commonService.getLoggedInUser();
      this.setUserDetail();
    });

    // if (this.loginUser.roleId !== 100 && this.loginUser.roleId !== 444) {
    //   this.getNavItems(this.loginUser);
    // }else if(this.loginUser.roleId === 444 && this.loginUser.roleId !== 100) {
    //   this.navItems = navItemsPartnerAdmin
    // }else if(this.loginUser.roleId === 100) {
    //   this.navItems = navItemsSuperAdmin;
    // }
    if(!!this.loginUser && this.loginUser.roleId !== null && this.loginUser.roleId !== undefined) {
      this.getNavItems(this.loginUser);
    }
   
  }

  getNavItems(user) {
    this._commonService.get<any>(urlConstant.Admin.getMenuRoleMasterByRole + `?userRoleId=` + user.roleId + `&partnerId=` + user.partnerId +`&menuAssignedFor=web` ).subscribe((res: any) => {
      if (res.Status === '200') {
        this.navItemsList = res.data['MenuRoleMasterDto'];
        if (this.navItemsList.length > 0) {
          this.setNavData(this.navItemsList)
        }
      }else {
        let nav;
        if (this.loginUser && this.loginUser.roleId === 222) {
          nav = navItems;
        }else
        if (this.loginUser && this.loginUser.roleId === 111) {
          nav = navItemsOwner;
        }else
        if (this.loginUser && this.loginUser.roleId === 333) {
          nav = navItemsTenants;
        }else
        if (this.loginUser && this.loginUser.roleId === 555) {
          nav = navItemsVendor;
        }else
        if (this.loginUser && this.loginUser.roleId === 444) {
          nav = navItemsPartnerAdmin;
        }else
        if(this.loginUser.roleId === 100 ) {
          nav = navItemsSuperAdmin
        }else {
          nav = null;
        }
        this.navItems = nav;
      }
      // else if(res.Status === '400') {
      //   this.unAssignedList = res.data['unassignedModules'];
      //   if(this.unAssignedList.length > 0) {
      //     this.setNavData(this.unAssignedList)
      //   }
      // }
    })
  }

  setNavData(navItemsList: any[]) {


    for (let i = 0; i < navItemsList.length; i++) {
      if (navItemsList[i].subMenuList.length > 0) {

        this.navItemsDynamic.push(
          {
            name: navItemsList[i].menuName,
            icon: navItemsList[i].menuIcon,
            children: [],
          }
        );
        for (let j = 0; j < navItemsList[i].subMenuList.length; j++) {

          if (this.navItemsDynamic.length > 0) {
            for (let k = 0; k < this.navItemsDynamic.length; k++) {
              if (k === i) {
                if (navItemsList[i].menuId === navItemsList[i].subMenuList[j].menuId) {
                  if(this.loginUser.roleId === 444) {
                    if(navItemsList[i].subMenuList[j].subMenuName === "Task") {
                      navItemsList[i].subMenuList[j].subMenuUrl = "/admin/dashboard/view-all/task-reminder/admin-task"
                    }
                  }
                  this.navItemsDynamic[k].children.push(
                    {
                      name: navItemsList[i].subMenuList[j].subMenuName,
                      url: navItemsList[i].subMenuList[j].subMenuUrl,
                      icon: navItemsList[i].subMenuList[j].subMenuIcon,
                    }
                  )
                }
              }
            }
          }
        }
      } else {
        this.navItemsDynamic.push(
          {
            name: navItemsList[i].menuName,
            url: navItemsList[i].menuUrl,
            icon: navItemsList[i].menuIcon,
          }
        );
      }
    }
    this.navItems = this.navItemsDynamic;
  }

  setUserDetail() {
    if (this.loginUser && this.loginUser.roles.length > 0) {
      this.role = this.loginUser.roles[0].name;
    }

    this.username = this.loginUser.firstName + (this.loginUser.lastName ? ' ' + this.loginUser.lastName : ' ');
    const matches = this.username.match(/\b(\w)/g);
    this.userShortName = matches.join('');
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.changes.disconnect();
    if (!!this.subscription) {
      this.subscription.unsubscribe();
    }
  }



  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('user');
    this.router.navigate(['/login']);
  }
}
