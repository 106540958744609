import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { ToasterConfig } from 'angular2-toaster';
import { NgxSpinnerService } from 'ngx-spinner';
import {urlConstant} from './constant/urlConstant';
import {CommonService} from './common/commonService';
import {CmsService} from './common/cms.service';
import { getSupportedInputTypes, Platform, supportsPassiveEventListeners, supportsScrollBehavior } from '@angular/cdk/platform';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  public toasterconfig: ToasterConfig = new ToasterConfig({ tapToDismiss: true, timeout: 5000,typeClasses: {
    error: 'custom-toast-error',
    success: 'custom-toast-success'
  } });

  supportedInputTypes = Array.from(getSupportedInputTypes()).join(', ');
  supportsPassiveEventListeners = supportsPassiveEventListeners();
  supportsScrollBehavior = supportsScrollBehavior();
  hostname: any;
  constructor(private router: Router, private spinner: NgxSpinnerService,
              public cmsService: CmsService,public platform: Platform
  ) {

    this.hostname = window.location.hostname
    if (this.hostname === "rentkochi.easerent.com") {
      this.favIcon.href = "assets/faviconKochi.ico";
    }else {
      this.favIcon.href = "assets/favicon.ico";
    }
    this.router.events.subscribe((event) => {
      switch(true) {
          case event instanceof NavigationStart: {
              // this.spinner.show();
              this.cmsService.getLabels();
              break;
          }

          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
              // this.spinner.hide();
              break;
          }

          default: {
              break;
          }
      }
    });
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
