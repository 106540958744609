import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {urlConstant} from '../constant/urlConstant';
import {CommonService} from '../common/commonService';

@Injectable({
  providedIn: 'root'
})
export class RentPaymentService {
  public propertyId: string;
  public property: any = {};
  public propertyData: any = [];
  oneRent : any;
  tenantData: any = [];
  public NoAccount = false;
  count: number;
  sDate: string;
  
  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private commonService: CommonService) {
    // this.propertyId = this.route.snapshot.params.id;

  }

  getValues(key) {

  }

  setValues(key, data) {
    this.property[key] = data;
    this.updateLocalProperty();
  }

  initLocalStorage() {
    const property = JSON.parse(localStorage.getItem(this.propertyId));
    if (!property || Object.keys(property).length === 0) {
      localStorage.setItem(this.propertyId, JSON.stringify({}));
      this.property = {};
    } else {
      this.property = property;
    }
  }

  updateLocalProperty() {
    localStorage.setItem(this.propertyId, JSON.stringify(this.property));
  }

  

  getUser(roleId) {
    return new Promise(((resolve, reject) => {
      this.commonService.get<any>(urlConstant.Property.getUserDetailByPropertyId + '?propertyId=' + this.propertyId).subscribe(res => {
        if (res.Status === '200') {
          const userDetailList = res.data.UserDetailList;
          if (userDetailList.length > 0) {
            const ownerData = userDetailList.filter(item => {
              return item.user.roleId === roleId;
            });
            if (ownerData.length > 0) {
              // this.property.owner = ownerData[0].user;
              this.updateLocalProperty();
              resolve(ownerData);
            } else {
              reject('No Owner Available');
            }
          }
        } else {
          reject('Error in API');
        }
      });
    }));
  }

  getBankDetail(uid) {
    return new Promise(((resolve, reject) => {
      if (this.property.account && this.checkPropertyBlankObject('account')) {
        resolve(this.property.account);
        return;
      }
      this.commonService.get<any>(urlConstant.RentPayment.GetUserBankAccountDetailsByUserId + '?userId=' + uid).subscribe(res => {
        if (res.Status === '200') {
          resolve(res.data.UserBankAccountDetails);
          return;
        } else {
          reject('No Bank Details available');
        }
      }, (error) => {
        reject('No Bank Details Available');
      });
    }));
  }

  getBrokerBankDetail(uid) {
    return new Promise(((resolve, reject) => {
      if (this.property.brokeraccount && this.checkPropertyBlankObject('brokeraccount')) {
        resolve(this.property.brokeraccount);
        return;
      }
      this.commonService.get<any>(urlConstant.RentPayment.GetUserBankAccountDetailsByUserId + '?userId=' + uid).subscribe(res => {
        if (res.Status === '200') {
          resolve(res.data.UserBankAccountDetails);
          return;
        } else {
          reject('No Bank Details available');
        }
      }, (error) => {
        reject('No Bank Details Available');
      });
    }));
  }

  getTenants() {
    return new Promise((async (resolve, reject) => {
      let tenants: any = await this.getUser(333);
      tenants = tenants.map(tenant => {
        const user = tenant.user;
        user.isPrimary = tenant.userDetailList[0].isPrimary === 'Y';
        return user;
      });
      if (this.property.tenants && this.property.tenants.length > 0) {
       
        const localTenants = this.property.tenants.filter(tenant => {
          if (tenant.isLocal) {
            return tenant;
          }
        });
      }
      resolve(tenants);
    }));
  }

  getTenant() {
    if(this.property.tenants && Object.keys('tenant')) {
      return this.property.tenants;
    }else {
      this.oneRent = JSON.parse(localStorage.getItem('updateOne'));
      return {firstName: this.oneRent.firstName, lastName: this.oneRent.lastName, email: this.oneRent.emailAddress, number:this.oneRent.phoneNumber, panCardNumber: this.oneRent.panCardNumber}
    }
  }

  getRentAgreement() {
    if (this.property.rentagreement && Object.keys('rentagreement')) {
      return this.property.rentagreement;
    } else {
      this.oneRent = JSON.parse(localStorage.getItem('propertyDetail'));
      const start = new Date(this.oneRent.rentStartDate);
      const sDate = (start).toISOString().substring(0, 10);
      const end = new Date(this.oneRent.rentEndDate);
      const eDate = (end).toISOString().substring(0, 10);
     
      return {startDate: sDate, endDate: eDate};
    }
  }

  getMonthlyRent() {
    if (this.property.monthlyrent && Object.keys('monthlyrent')) {
      return this.property.monthlyrent;
    } else {
      const todayDate = new Date();
      const todayDateStr = (todayDate).toISOString().substring(0, 10);
      this.oneRent = JSON.parse(localStorage.getItem('propertyDetail'));
      const dt = this.oneRent.dueDate;
      return {monthlyDueDate: dt, monthlyRentAmount: this.oneRent.monthlyRent, rsymbol: this.oneRent.rsymbol};
    }
  }

  getMonth() {
    if (this.property.monthlyrent && Object.keys('monthlyrent')) {
      return this.property.monthlyrent;
    } else {
      const todayDate = new Date();
      const todayDateStr = (todayDate).toISOString().substring(0, 10);
      this.oneRent = JSON.parse(localStorage.getItem('propertyPaymentDetail'));
      const start = new Date(this.oneRent.startDate);
      const sDate = (start).toISOString().substring(0, 10);
        return { rentColletionStartOn: sDate};
    }
  }

  getDeposit() {
    if (this.property.deposit && Object.keys('deposit')) {
      return this.property.deposit;
    } else {
      const todayDate = new Date();
      const todayDateStr = (todayDate).toISOString().substring(0, 10);
      this.oneRent = JSON.parse(localStorage.getItem('propertyDetail'));
      const due = new Date(this.oneRent.securityDepositDate);
      if(this.oneRent.securityDepositDate == null) {
        this.sDate = '';
      }
      this.sDate = (due).toISOString().substring(0, 10);
      return { securityDepositDueDate: this.sDate,securityDepositAmount: this.oneRent.securityDeposit, dsymbol: this.oneRent.dsymbol, isCollectSecurityDeposit: true};
    }
  }


  getSecurityDeposit() {
    if (this.property.deposit && Object.keys('deposit')) {
      return this.property.deposit;
    }else {
      this.oneRent = JSON.parse(localStorage.getItem('updateOneRents'));
      const due = new Date(this.oneRent.dueDate);
      const sDate = (due).toISOString().substring(0, 10);
      return { securityDepositDueDate: sDate}
    }
    return null;
  }


  getAdditionalDeposit() {
    if (this.property.deposit && Object.keys('deposit')) {
      return this.property.deposit;
    } else {

      this.oneRent = JSON.parse(localStorage.getItem('propertyPaymentDetail'));
      const due = new Date(this.oneRent.dueDate);
      const sDate = (due).toISOString().substring(0, 10);
     return {dueDate: sDate,  amount: this.oneRent.amount, isAdditionalMoveInCost: true};
    }
  }

  getProrate() {
    if (this.property.monthlyrent && Object.keys('monthlyrent')) {
      return this.property.monthlyrent;
    }else {
      this.oneRent =  JSON.parse(localStorage.getItem('propertyPaymentDetail'));
      const due = new Date(this.oneRent.dueDate);
      const sDate = (due).toISOString().substring(0, 10);
      return {  prorateRentDueDate: sDate, isCollectProratedRent: true, prorateRentAmount: this.oneRent.amount}
    }
  }

  getBrokers() {
    if (this.property.broker_fees && Object.keys('broker_fees')) {
      return this.property.broker_fees;
    }else {
      this.oneRent =  JSON.parse(localStorage.getItem('updateOneRents'));
    }
  }

  getBrokerFees() {
    if (this.property.broker_fees && Object.keys('broker_fees')) {
      return this.property.broker_fees;
    } else {
      const todayDate = new Date();
      const todayDateStr = (todayDate).toISOString().substring(0, 10);
      return {brokerFeesDueDate: todayDateStr, brokerFees: ''};
    }
  }

  checkPropertyBlankObject(key) {
    return Object.keys(this.property[key]).length > 0;
  }

}
