import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LegalDocComponent} from './legal-doc.component';
import {MatDialogModule} from '@angular/material';
import {PrivacyPolicyComponent} from '../../views/public/privacy-policy/privacy-policy.component';
import {TermsServiceComponent} from '../../views/public/terms-service/terms-service.component';
import {DisclaimerPolicyComponent} from '../../views/public/disclaimer-policy/disclaimer-policy.component';
import {RefundAndCancellationPolicyComponent} from '../../views/public/refund-and-cancellation-policy/refund-and-cancellation-policy.component';
import {ContactAndSupportComponent} from '../../views/public/contact-and-support/contact-and-support.component';
import {FormsModule} from '@angular/forms';
@NgModule({
  declarations: [
    LegalDocComponent,
    PrivacyPolicyComponent,
    TermsServiceComponent,
    DisclaimerPolicyComponent,
    ContactAndSupportComponent,
    RefundAndCancellationPolicyComponent,
  ],
    imports: [
        CommonModule,
        MatDialogModule,
        FormsModule,
    ],
  exports: [
    LegalDocComponent,
    PrivacyPolicyComponent,
    TermsServiceComponent,
    DisclaimerPolicyComponent,
    ContactAndSupportComponent,
    RefundAndCancellationPolicyComponent,
  ],
  entryComponents: [
    LegalDocComponent,
  ],
})
export class LegalDocModule { }
