export const dataConstant = {
  MessageType: {
    danger: 'danger',
    warning: 'warning',
    success: 'success-msg',
    failure: 'failure',
    error: 'Error-msg',
  },
  APIStatus: {
    Success: 'Success',
    Failure: 'Failure',
    Warning: 'Warning',
    RecordNotFound: 'Record Detail Not Exist',
    Exists: 'Exists',
    NotFound: 'NotFound',
    DuplicateRecord: 'DuplicateRecord',
    ChildRecordExist: 'ChildRecordExist'
  },
  StatusCode: {
    //Success
    200: '200',//OK
    201: '201',//Created
    204: '204',//No Content
    //Redirection
    304: '304',//Not Modified
    //Client Error
    400: '400',//Bad Request
    401: '401',//Unauthorized
    402: '402',//Created
    403: '403',//Forbidden
    404: '404',//Not Found
    409: '409',//Conflict
  },
  datePattern: /^\d{2}-\d{2}-\d{4}$/,
  NumberWithDecimal: /^[0-9]+(\.[0-9]{1,2})?$/,
  DecimalWithSevenPointPattern: /^[1-9][0-9]{0,4}(?:,?[0-9]{3}){0,2}(?:\.[0-9]{0,7})?$/,
  Numberony: /^[0-9]*$/,
  PasswordPattern: /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?!.*\s).{6,}$/,
  PhoneNoPattern: /^(?:\+?\d{1,3}\s*-?)?\(?(?:\d{3})?\)?[- ]?\d{3}[- ]?\d{4}$/,
  EmailPattren: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  WeekDay: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  Months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  DateFormatArray:
    ['dd/MM/yyyy'                       //00. 13-12-2018
      , 'dd-MMMM-yyyy'                //01. 13-12-2018
      , 'yyyy/MM/dd'                  //02. 2018/12/13
      , 'dd.MM.yyyy'                  //03. 13.12.2016
      , 'shortDate'                   //04.
      , 'MM/dd/yyyy'                  //05. 12/13/2018
      , 'MM/dd/yyyy HH:mm'            //06. 12/13/2018 15:12
      , 'yyyy-MM-dd hh:mm a'          //07. 2018-12-13 03:12 PM
      , 'yyyy/MM/dd hh:mm a'          //08. 2018/12/13 03:12 PM
      , 'yyyy-MM-dd'                  //09. 2018-12-13
      , 'hh:mm a'                     //10. 03:12 PM
      , 'MMM dd,yyyy hh:mm a'         //11. DEC 13,2018 03:12 PM
      , 'dd MMMM, yyyy'               //12. 13 DECEMBER, 2018
      , 'dd MMM yyyy'                 //13. 13 DEC, 2018
      , 'MM/DD/YYYY  HH:mm:ss'        //14. 12/13/2018 15:12:20
    ],

  Pagination: {
    IsCSVExport: 'false',
    ItemsPerPage: 10,
    Page: 1,
    SearchColumns: [],
    SortColumns: []
  },
  ModuleNames: {
    User: {
      Name: 'User'
    }
  },
  PageSize: [10, 25, 50, 75, 100],
  DefaultImage: 'no_image.png',
  SearchDataType:
  {
    StringContains: 'StringContains',
    StringEquals: 'StringEquals',
    DateTime: 'DateTime',
    Date: 'Date',
    Number: 'Number',
    Decimal: 'Decimal',
    LongNumber: 'LongNumber',
    Boolean: 'Boolean',
  },
  Status: [
    { name: 'Active', value: true },
    { name: 'InActive', value: false },
  ],
  EnableColumnFiltering: true,
  IsPinOption: true,
  IsShowHideColumn: true,
  IsShowClearFilter: true,
  DisplayDateFormat: 'dd/MM/yyyy',
  MinTypeAheadChar: 4,
  Admin: 'Admin',
  DefaultStatus: [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
  ],

  PropertyStatus: {
    moveIn: 'MOVE-IN',
    moveOut: 'MOVE-OUT',
    propertyMove: 'PROPERTY-MOVE'
  },

  PropertyType: [
    { key: 'Good', value: 'Everything inspected !!' },
    { key: 'Issue I Can Live With', value: 'Notifying, but okay with not being fixed' },
    { key: 'Issues - needs fixing', value: 'Everything inspected !!' }
  ],
  StatusMoveInId: {
    MoveInInvitationSend: 1333,
    MoveInStarted: 1444,
    MoveInTanentStarted: 1555,
    MoveInPMStarted: 1666,
    MoveInStartedSUBMITTED: 1777,
  },
  StatusMoveOutId: {
    MoveOutInvitationSend: 2333,
    MoveOutStarted: 2444,
    MoveOutPMSubmitted: 2555,
    MoveOutTenantSubmitted: 2666,
    MoveOutFinalSUBMITTED: 2777,
  },
  statusList: [
    { id: 'New', name: 'New' },
    { id: 'Pending', name: 'Pending' },
    { id: 'Scheduled', name: 'Scheduled' },
    { id: 'Verify', name: 'Verify' },
    { id: 'Work in progress', name: 'Work in progress' },
    { id: 'Resolved', name: 'Resolved' },
    { id: 'Complete', name: 'Complete' },
    { id: 'Review Completed', name: 'Review Completed' },
    { id: 'Quote Taken', name: 'Quote Taken' },
    { id: 'Quote Ready', name: 'Quote Ready' },
    { id: 'Quote Approved', name: 'Quote Approved' },
  ],
  colors: [
    '#34568B',
    '#FF6F61',
    '#6B5B95',
    '#88B04B',
    '#F7CAC9',
    '#92A8D1',
    '#B55A30',
    '#FDAC53',
    '#F5DF4D',
    '#0072B5',
    '#A0DAA9',
    '#E9897E'
  ],
  propertyType: [
    { id: 'House/Villa', name: 'House/Villa' },
    { id: 'Commercial Property', name: 'Commercial Property' },
    { id: 'Office', name: 'Office' },
    { id: 'Retail', name: 'Retail' },
    { id: 'Storage', name: 'Storage' },
    { id: 'Hospitality', name: 'Hospitality' },
    { id: 'Apartment', name: 'Apartment' },
    { id: 'Studio', name: 'Studio' },
    { id: 'Showroom', name: 'Showroom' },
    { id: 'Warehouses', name: 'Warehouses' },
    { id: 'Plot & Farmhouse', name: 'Plot & Farmhouse' },
    { id: 'multiUnit', name: 'Builder Multi Unit' },
    { id: 'pg', name: 'Paying Guest' },
  ],
  propertyDetailName: {
    fan: 'Fan',
    washineMachine: 'Washine Machine',
    sofa: 'Sofa',
    bed: 'Bed',
    stove: 'Stove',
    ac: 'AC',
    light: 'Light',
    projectName: 'Project Name',
    propertyDescription: 'PropertyDescription',
    floor: 'Floor',
    coveredArea: 'Covered Area',
    carpetArea: 'Carpet Area',
    flooringType: 'Flooring type',
    nearByInformation: 'Near by Information',
    facing: 'Facing',
    numberOfParking: 'Number of Parking',
    parkingNumber: 'Parking Number',
    allotedParking: 'Alloted Parking',
    ageOfProperty: 'Age of Property',
    willingToRentToFamily: 'Willing To Rent To Family',
    willingToRentToMen: 'Willing To Rent To Men',
    willingToRentToSingleWomen: 'Willing To Rent To SingleWomen',
  }
};

export const statusIcons = {
  'GOOD': 'check_circle_outline',
  'FAIR': 'star_border',
  'REPAIR': 'build',
  'REPLACE': 'shuffle',
  'OTHER': 'extension',
}

export const priceNorms = [
  { key: "K", multiplier: 1000, label: "K" },
  { key: "Lac", multiplier: 100000, label: "Lac" },
  { key: "Cr", multiplier: 10000000, label: "Cr" },
];

export const VideoExt = ['.mpg', '.mp2', '.mpeg', '.mpe', '.mpv', '.mp4'];

export const RoleList = [
  { id: '*', name: 'All' },
  { id: '111', name: 'Owner' },
  { id: '222', name: 'Property Manager' },
  { id: '333', name: 'Tenant' },
]
export const InspectionAutoInitiatorConst = (inspectionType) => {
  const status = [];
  const dates = [];
  let days = Array.from(new Array(26)).map((t, i) => {
    const day = i + 1;
    return { name: day, value: day };
  })
  if (inspectionType == 'MOVE-IN') {
    status.push({ name: 'Rent Start Date', value: 'Rent_Start_Date' });
    dates.push({ name: 'Before', value: 'before' });
    dates.push({ name: 'After', value: 'after' });
    days.push({ name: 30, value: 30 });

  } else if (inspectionType == 'MOVE-OUT') {
    status.push({ name: 'Rent End Date', value: 'Rent_End_Date' });
    dates.push({ name: 'Before', value: 'before' });
    dates.push({ name: 'After', value: 'after' });
    days = Array.from(new Array(13)).map((t, i) => {
      const day = i + 1;
      return { name: day, value: day };
    })
  } else if (inspectionType == 'HALF-YEAR') {
    status.push({ name: 'Rent Start Date', value: 'Rent_Start_Date' });
    dates.push({ name: 'After', value: 'after' });
    days.push({ name: 30, value: 30 });
    days.push({ name: 60, value: 60 });
    days.push({ name: 90, value: 90 });
    days.push({ name: 120, value: 120 });
    days.push({ name: 180, value: 180 });

  } else if (inspectionType == 'QUARTERLY') {
    status.push({ name: 'Rent Start Date', value: 'Rent_Start_Date' });
    dates.push({ name: 'After', value: 'after' });
    days.push({ name: 30, value: 30 });
    days.push({ name: 60, value: 60 });
    days.push({ name: 90, value: 90 });
    days.push({ name: 120, value: 120 });

  } else if (inspectionType == 'MONTHLY') {
    status.push({ name: 'Every Month', value: 'Every_Month' });
    dates.push({ name: 'Of', value: 'of' });


  } else if (inspectionType == 'TAKE-OVER') {
    status.push({ name: 'Property Created Date', value: 'Property_Created_Date' });
    dates.push({ name: 'After', value: 'after' });
  }
  return {
    status: status,
    dates: dates,
    days: days
  }
};

export const sideMenuList = {
  url: {
    Dashboard: 'dashboard',
    Properties: 'properties',
    RentPayment: 'rentPayment',
    BillsUtilities: 'billsUtilities',
    Documents: 'documents',
    ServiceRequest: 'service/list',
    MyVendorTeam: 'service/vendors',
    LeadManagement: 'inquiry/home',
    Inspection: 'app-inspection/landing',
    InspectionQuote: 'app-inspection/quote',
    Customers: '',
    Report: 'report'
  },
  icon: {
    Dashboard: 'icon-speedometer',
    Properties: 'icon-home',
    RentPayment: 'icon-note',
    BillsUtilities: 'icon-energy',
    Documents: 'icon-doc',
    ServiceRequest: 'icon-doc',
    MyVendorTeam: 'icon-doc',
    LeadManagement: 'icon-people',
    Inspection: 'icon-doc',
    InspectionQuote: 'icon-doc',
    Customers: 'icon-people',
    Report: 'icon-doc'
  }
};

export const sideSubMenuList = {
  url: {
    Property: 'dashboard',
    Financial: 'dashboard/view-all/financial-dashboard',
    Lead: 'dashboard/view-all/lead-management-dashboard',
    Task: 'dashboard/view-all/task-reminder',
    ByLead: 'inquiry/home',
    ByProperty: 'inquiry/property-lead-management',
    Tenants: 'intake/tenants',
    Owners: 'intake/owners',
  },
  icon: 'icon-arrow-right',
};

export const sequenceList = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20
]