import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class CsvDownload {

  transformHeader(header) {
    return header.replace(/([A-Z])/g, ' $1') // Add space before capital letters
                 .replace(/^./, function(str){ return str.toUpperCase(); }) // Capitalize the first letter
                 .trim(); // Remove any leading/trailing spaces
  }
    
  downloadCsv(leadsToDownload: string[], selectedFields: string[], fieldMapping: { [key: string]: string }, filename: string = 'data.csv'): void 
  {
    const flattenedDataList = leadsToDownload.map(item => this.flattenObject(item));
    const filterData = flattenedDataList.map(item =>
      selectedFields.reduce((acc,field)=>{
        acc[field] = item[field];
        return acc;
      }, {}));
      const transformedHeaders = selectedFields.map(field => fieldMapping[field] || field); // Transform the headers
      this.downloadCsvFilefromJson(filterData, selectedFields,transformedHeaders);
  }

  flattenObject(obj: any, parentKey = '', result = {}): any {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = parentKey ? `${parentKey}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          this.flattenObject(obj[key], newKey, result);
        } else {
          result[newKey] = obj[key];
        }
      }
    }
    return result;
  }

  downloadCsvFilefromJson(data: any[], headers: string[], transformedHeaders:string[]) : void
  {
    if(!data.length)
    {
      return;
    }
    const csvData = this.convertedToCsv(data, headers,transformedHeaders);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none');
    a.href = url;
    a.download = 'data.csv'
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  convertedToCsv(data: any[],fields: string[],transformedHeaders: string[])
  {
    const replacer = (key, value) => (value === null || value === undefined ? '' : value); // handle null and undefined values
    const header = transformedHeaders.join(',');
    const csv = data.map(row => fields.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    return [header, ...csv].join('\r\n');
  }
}
