import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { urlConstant } from '../../../constant/urlConstant';

@Component({
  selector: 'app-contact-success',
  templateUrl: './contact-success.component.html',
  styleUrls: ['./contact-success.component.scss']
})
export class ContactSuccessComponent implements OnInit {
    currentProperties: any;
    dataDate: any;
    dataTime: any;
    pmDetails:any;
    data: any;
    constructor(  public router: Router, private route: ActivatedRoute
    ) { }
    previousUrl: string;
    sid:string;
    public getPreviousUrl(){
      localStorage.removeItem('saveTime');
      localStorage.removeItem('saveDate');
      localStorage.removeItem('saveProperties');
      console.log(this.previousUrl)
      return this.router.navigate(['/property/search'],{ queryParams: { sid:this.sid }});
      // ,{ queryParams: { sid:this.sid}});
    } 
   // this.router.navigate(['/property/search/contact'],{ queryParams: { sid:this.sid }});
    ngOnInit(): void { 
        this.dataTime =JSON.parse(localStorage.getItem('saveTime'));
        this.dataDate =JSON.parse(localStorage.getItem('saveDate'));
        this.currentProperties=JSON.parse(localStorage.getItem('saveProperties'));
        this.pmDetails=JSON.parse(localStorage.getItem('propertyMangerDetails'));
        
        console.log(this.currentProperties);

        this.sid = this.route.snapshot.queryParamMap.get('sid');
    }
} 

