import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocViewerComponent } from './doc-viewer.component';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SafePdfPipePipe } from '../safe-pdf-pipe.pipe';

@NgModule({
  declarations: [
    DocViewerComponent,
    SafePdfPipePipe
  ],
  imports: [
    CommonModule,
    MatDialogModule,
  ],
  exports: [
    DocViewerComponent,
  ],
  entryComponents: [
    DocViewerComponent,
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ]
})
export class DocViewerModule { }
