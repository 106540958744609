import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {CmsContent, CmsService} from './cms.service';
import {Subscription} from 'rxjs';

@Injectable()
export class BaseComponent implements OnInit, OnDestroy {

  cmsContent: CmsContent;
  subscribe: Subscription = undefined;

  constructor(private cms: CmsService) {
    this.updateCmsContent(cms.cmsSubject.value);
  }

  ngOnInit() {
    this.subscribe = this.cms.cmsObservable.subscribe(content => {
      this.updateCmsContent(content);
    });
  }

  updateCmsContent(cmsContent: CmsContent) {
    this.cmsContent = cmsContent;
  }

  ngOnDestroy() {
    if (!!this.subscribe) {
      this.subscribe.unsubscribe();
    }
  }

}
