import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from 'angular2-toaster/src/toaster.service';
import { CommonService } from '../../../common/commonService';
import { dataConstant } from '../../../constant/dataConstant';
import { urlConstant } from '../../../constant/urlConstant';
import { MobileScheduleTourComponent } from '../mobile-schedule-tour/mobile-schedule-tour.component';
import { SwiperOptions } from 'swiper';
import {Gallery, ImageItem, GalleryItem} from '@ngx-gallery/core';

@Component({
  selector: 'app-mobile-details',
  templateUrl: './mobile-details.component.html',
  styleUrls: ['./mobile-details.component.scss']
})
export class MobileDetailsComponent implements OnInit {

  @Input() propertyData:any;
  @Input() index:any;
  @Input() manager:any=[];
  @Input() images:any=[];
  additionalPropertyDetailsList = [];
  propertiesDetail= [];
  propertyAmenities = [];
  propertyAmenitiesList = [];
  propertyDecription: any;
  propertyList: any = [];
  showList: boolean = false;
  showParticular: boolean = true;
  managerDetail: any;
  propertyManagerDetails: any=[];
  ageOfProperty:any;
  coveredArea: any;
  houseArea: any;
  bathroom: any;
  noOfFloor: any;
  floor: any;
  bedroom: any;
  furnitures: any[];
  sid: string;
  shareUserDetail: any;
  items: GalleryItem[];
 
  constructor(
    public dialog: MatDialog,
    public _CommonService: CommonService,
    public toasterService: ToasterService,
    private route: ActivatedRoute,
    public gallery: Gallery) 
    { 
      this.sid = this.route.snapshot.queryParamMap.get('sid');
    }
    
  config: SwiperOptions = {
    // pagination: { 
    //   el: '.swiper-pagination', 
    //   clickable: true 
    // },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 0,
    init: true,
    enabled: true,
    zoom: {
      maxRatio: 5,
    }
  };

  ngOnInit() {
    this.propertyDetails();
    this.getShareUserDetails();
    
    if(this.images){
      this.items = this.images.map(res => new ImageItem({ src: res.filePath }));
      const galleryRef = this.gallery.ref();
      galleryRef.load(this.items);
    }else if(this.propertyData.coverImage){
      this.items = [ new ImageItem({ src: this.propertyData.coverImage }) ];
      const galleryRef = this.gallery.ref();
      galleryRef.load(this.items);
    }else if(!this.propertyData.coverImage){
      this.items = [ new ImageItem({ src: '/assets/img/Properties/6.jpg' }) ];
      const galleryRef = this.gallery.ref();
      galleryRef.load(this.items);
    }
  }

  isVideo(image: any): boolean {
    const videoExtensions = ['mp4', 'webm', 'ogg'];
    const extension = image.filePath.split('.').pop().toLowerCase();
    return videoExtensions.includes(extension);
  }

  propertyDetails() {
    this.propertyAmenitiesList = [{}];
    if(this.propertyData.propertyDetails.length > 0 && this.propertyData.propertyDetails !== null) { 
      this.additionalPropertyDetailsList = this.propertyData.propertyDetails[0].additionalPropertyDetails
      if (this.additionalPropertyDetailsList) {
       this.ageOfProperty = this.additionalPropertyDetailsList.filter(item => {
          if(item.propertyAddtionalType === 'ageOfProperty') {
            return item;
          }
        });
        this.coveredArea = this.additionalPropertyDetailsList.filter(item => {
          if(item.propertyAddtionalType === 'coveredArea') {
            return item;
          }
        })
        this.bathroom = this.propertyData.propertyDetails[0].bathRoom
        this.bedroom = this.propertyData.propertyDetails[0].bedRoom;
       this.noOfFloor = this.additionalPropertyDetailsList.filter(item => {
          if(item.propertyAddtionalType === 'floor') {
            return item;
          }
       });
      }
      if(this.ageOfProperty.length > 0) {
        this.ageOfProperty = this.ageOfProperty[0].propertyAddtionalValue;
      }
      if(this.coveredArea.length > 0){
        this.houseArea = this.coveredArea[0].propertyAddtionalValue;
      }
      if(this.noOfFloor.length > 0){
        this.floor = this.noOfFloor[0].propertyAddtionalValue;
      }
      var propertyAmenities = [];
      this.propertyData.propertyDetails[0].propertyAmenities.filter((item) => {
        if (item.propertyAmenitiesKey == "propertyAmenities") {
          this.propertyDecription = item.propertyAmenitiesType;
        }

        if (item.propertyAmenitiesValue == "true" && !propertyAmenities.includes(item.propertyAmenitiesType)) {
          propertyAmenities.push(item.propertyAmenitiesType);
          this.propertyAmenitiesList.push(item);
        }
      });

      this.propertyAmenities =  this.propertyData.propertyDetails[0].propertyAmenities;

   
      this.furnitures =  this.additionalPropertyDetailsList.filter((item) => {
          if(item.propertyAddtionalKey === "Furnished") {
            return item;
          }
        })     
    }
    this.setManagerDetails(this.propertyData)
  }

  
  showProperty() {
    this.showList = !this.showList;
    this.showParticular = !this.showParticular
  }

  setManagerDetails(property) {
    this.managerDetail = null;
    const managerDetail = this.manager[property.propertyId];
    if (!managerDetail) {
      return;
    }
    this.managerDetail = managerDetail;
    localStorage.setItem('propertyMangerDetails', JSON.stringify(this.managerDetail));
  }

  getShareUserDetails(){
    this._CommonService.get<any>(urlConstant.Property.getShareUserDetails + '?sid='+this.sid).subscribe(res => {
      if(res.Status === '200'){
        this.shareUserDetail = res.data.shareUserDetails;
        console.log("data",res.data);
      }
    })
  }

  getPropertyDetailNames(key) {
    return dataConstant.propertyDetailName[key] ? dataConstant.propertyDetailName[key] : key;
  }

  schedule() {
    const dialogRef = this.dialog.open(MobileScheduleTourComponent, {
      data:this.shareUserDetail
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
