import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-legal-doc',
  templateUrl: './legal-doc.component.html',
  styleUrls: ['./legal-doc.component.scss']
})
export class LegalDocComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LegalDocComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.closeModel = this.closeModel.bind(this);
  }

  ngOnInit() {
  }

  closeModel() {
    this.dialogRef.close({});
  }

}
