import { Component, OnInit } from '@angular/core';
import { dataConstant } from '../../constant/dataConstant';
import { CommonService } from '../../common/commonService';
import { Router, ActivatedRoute } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import {urlConstant} from '../../constant/urlConstant';
import {BaseComponent} from '../../common/base.component';
import {CmsService} from '../../common/cms.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends BaseComponent {
  loginuserdetail: any;
  resetPasswordModel: any = {};
  userData: any = {};
  isSubmit = false;
  passwordPattern = dataConstant.PasswordPattern;
  error;

  constructor(public _commonService: CommonService,
              private _activatedRoute: ActivatedRoute,
              cms: CmsService,
              public toasterService: ToasterService,
              public router: Router) {
    super(cms);
  }

  ngOnInit() {
    super.ngOnInit();
    this._activatedRoute.params.subscribe(params => {
      this.resetPasswordModel.Token = params['token'];
      this.verifyToken();
    });
  }

  verifyToken() {
    this.error = null;
    this._commonService.get(`${urlConstant.Auth.validToken}${this.resetPasswordModel.Token}`)
      .subscribe((response: any) => {
        this._commonService.hideLoading();
        if (response && response.Status === '200') {
          this.resetPasswordModel.username = response.data.emailId;
        } else {
          this.isSubmit = false;
          this.toasterService.pop('error', 'Error', response.message);
          this.error = response.message;
        }
      }, error => {
        this.isSubmit = false;
        this._commonService.hideLoading();
        if (error != null) {
          this.toasterService.pop('error', 'Error', error.message);
        }
      });
  }

  resetPasssword(resetPasswordForm) {
    this.error = null;
    this.isSubmit = true;
    if (resetPasswordForm.form.invalid) {
      return;
    }
    if (this.resetPasswordModel && this.resetPasswordModel.Password !== this.resetPasswordModel.ConfirmPassword){
      return;
    }
    this._commonService.showLoading();
    this._commonService.post(urlConstant.Auth.resetUserRassword, {
      password: this.resetPasswordModel.Password,
      token: this.resetPasswordModel.Token,
    })
      .subscribe((response: any) => {
        this._commonService.hideLoading();
        if (response && response.Status === '200') {
          this.toasterService.pop('success', 'Success', response.message);
          this.router.navigate(['/login']);
        } else {
          this.isSubmit = false;
          this.toasterService.pop('error', 'Error', response.message);
          this.error = response.message;
        }
      }, error => {
        this.isSubmit = false;
        this._commonService.hideLoading();
        if (error != null) {
          this.toasterService.pop('error', 'Error', error.message);
        }
      });
  }

}
