import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FileUploadService } from './file-upload.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../common/commonService';
import { urlConstant } from '../../constant/urlConstant';
import { Observable } from 'rxjs/Rx';
import { RoleList } from '../../constant/dataConstant';
import { ToasterService } from 'angular2-toaster';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-multi-file-upload',
  templateUrl: './multi-file-upload.component.html',
  styleUrls: ['./multi-file-upload.component.scss']
})
export class MultiFileUploadComponent implements OnInit {
  documentCategories = [];
  showPermission = false;
  roleList = JSON.parse(JSON.stringify(RoleList));
  docCategory='';
  docCategoryDocType = '';
  
  propertyId: string;
  userId: string;
  comment = 'File';
  docType: string;

  selectedFiles?: FileList;
  progressInfos: any[] = [];
  clicked: boolean;
  success: boolean;
  failure: boolean;

  @ViewChild('editModal', { static: false }) editModal: TemplateRef<any>;
  @ViewChild('popupModal', { static: false }) popupModal: TemplateRef<any>;
  array: any[];
  document: any;
  uploadedImages: any[]=[];
  showComment = false;
  documentComment = new FormControl('');
  disabledUpload: boolean= false;
  docTypeId: any;
  estimate: any;
  constructor(
    private fileUploadService: FileUploadService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalService: NgbModal,
    private toasterService: ToasterService,
    private dialog: MatDialogRef<MultiFileUploadComponent>,
    private commonService: CommonService
  ) {
    this.propertyId = data.propertyId;
    this.userId = data.userId;
    this.comment = data.comment;
    this.docType = data.docType;
    this.docTypeId = data.docTypeId;
    this.showPermission = !!data.showPermission;
    this.showComment = !!data.showComment;
    if (this.showPermission || this.showComment) {
      this.comment = '';
      this.disabledUpload = true;
    }
    if(!!data && !!data.estimate) {
      this.estimate = data.estimate;
    }
  }

  ngOnInit(): void {
    this.getDocumentCategories();
  }

  openModal() {
    this.modalService.open(this.editModal);
  }
  docCategoryChange($event) {
    this.docCategory = $event.target.value;
    this.docCategoryDocType = '';
  }
  docCategoryDocTypeChange($event) {
    this.docCategoryDocType = $event.target.value;
  }
  commentChange($event) {
    this.comment = $event.target.value;
    if(this.showComment && this.selectedFiles.length > 0 && this.comment !== '' && this.comment !== null && this.comment !== undefined) {
      this.disabledUpload = false
    }
  }
  getDocumentCategories() {
    this.commonService.get<any>(urlConstant.User.documentCategories).subscribe((res) => {
      if (res.Status === '200') {
        this.documentCategories = res.data.documentCategories;
      }
    }, (error) => {
      if (error != null) {
      }
    });
  }
  

  roleCheckChangeEvent($event, eRoleObj) {
    eRoleObj.checked = $event.target.checked;
    if ($event.target.value === '*') {
      this.roleList.map((roleObj, i)=>{
        if (i !== 0 ) {
          roleObj.checked = false;
          if ($event.target.checked) {
            roleObj.disabled = true;
          } else {
            roleObj.disabled = false;
          }
        }
      })
    }
  }

  selectFiles(event: any): void {
    this.progressInfos = [];
    this.selectedFiles = event.target.files;

    if (this.selectedFiles.length > 10) {
      this.openModal();
    } else {
      if (this.selectedFiles) {
        for (let i = 0; i < 10; i++) {
          if (this.selectedFiles[i] !== undefined)
            this.progressInfos[i] = {
              value: 0,
              id: this.selectedFiles[i],
              name: this.selectedFiles[i].name,
              result: '',
              msg: ''
            };
        }
      }
      if(this.showComment && this.selectedFiles.length > 0 && this.comment !== '' && this.comment !== null && this.comment !== undefined) {
        this.disabledUpload = false
      }
    }
  }

  upload(idx: number, file: File): void {

    if (file) {
      this.commonService.showLoading();
      if (file.size < 250000000) {
        this.uploadMultipleFile(file, this.propertyId, this.userId, this.comment, this.docType).subscribe((event: any) => {
                 
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
          } else if (!!event) {
            let element = idx;
            let last = this.progressInfos.length - 1
            if (event['messageType'] === 'Success' && event['data'].Document !== null) {
              
              this.progressInfos[idx].result = 'Success';
              this.progressInfos[idx].msg = event['message'];
              this.progressInfos[idx].value = 100;
              this.uploadedImages.push(event.data.Document)
              for (let i = 0; i < this.progressInfos.length; i++) {
                if (idx === i) {
                  if (this.progressInfos[i].value === 100) {
                    if (idx === last) {
                      this.success = true;
                      this.clicked = false;
                      this.failure = false;
                    } 
                  } else {
                    this.failure = true;
                  }
                }
              }
            } else {
              if (event['messageType'] === 'Failure'){
                this.progressInfos[idx].result = 'Failed';
                this.progressInfos[idx].msg = event['message'];
                this.progressInfos[idx].value = 0;
                if (this.progressInfos[idx].value === 0) {
                  this.failure = true;
                  this.success = false;
                  this.clicked = false;
                }
              }
            }
            this.commonService.hideLoading();
          }
        },
          (err: any) => {
            this.commonService.hideLoading();
            this.progressInfos[idx].value = 0;
            const msg = 'Could not upload the file: ' + file.name;
            this.progressInfos[idx].result = 'Failed';
            this.progressInfos[idx].msg = msg;
          });
      }
      else {
        this.commonService.hideLoading();
        this.progressInfos[idx].value = 0;
        const msg = ' Could Not Upload The File: ' + file.name + ' As File Size Exceeded';
        this.progressInfos[idx].result = 'Failed';
        this.progressInfos[idx].msg = msg;
      }

    }
  }

  uploadMultipleFile(file: File, propertyId: string, userId: string, comment: string, docType: string): Observable<HttpEvent<any>> {
    const formData = new FormData();
    if (this.showPermission) {
    
      let docCategory, docCategoryDocType;
      const docCategoryObj = this.documentCategories.find(t => t.id === this.docCategory);
      if (docCategoryObj) {
        docCategory = docCategoryObj.name;
        if (docCategoryObj.documentTypes) {
          const docCategoryDocTypeObj = docCategoryObj.documentTypes.find(t => t.id === this.docCategoryDocType);
          if (docCategoryDocTypeObj) {
            docCategoryDocType = docCategoryDocTypeObj.name;
          }
        }
      }
  
      let permissions = this.roleList.filter((role) => role.checked).map(t=>t.id).join(',')+ ',444';
      if (!permissions) {
        permissions = '*'
      }
      formData.append('permissions', permissions);
      if (docCategory) {
        formData.append('docCategory', docCategory);
      }
      if (docCategoryDocType) {
        formData.append('docCategoryDocType', docCategoryDocType);
      }
    }

    if(this.estimate !== undefined && this.estimate !== null) {
      formData.append("estimateId", this.estimate.id);
    }

    formData.append('comment', comment);
    formData.append('docType', docType);
    if(!!this.docTypeId) {
      formData.append('docTypeId', this.docTypeId);
    }
    formData.append('file', file);
    formData.append('filePath', '');
    formData.append('propertyId', propertyId);
    formData.append('userId', userId);

    if (formData.get('comment') != null && formData.get('docType') != null && formData.get('file') != null &&
      formData.get('propertyId') != null && formData.get('userId') != null) {
      this.clicked = true;
    } else {
      this.failure = true;
    }

   console.log("ddd", formData)
    
    return this.commonService.postFormData(urlConstant.Property.UploadDocumentPropertyByPropertyId, formData);;
  }

  uploadFiles(): void {
    if (this.showPermission) {
      let permissions = this.roleList.filter((role) => role.checked).map(t=>t.id).join(',');
      if (!permissions) {
        this.toasterService.pop('error', 'Invalid data', "Please select permission.");
        return;
      }
    }
    if (this.selectedFiles) {
      for (let i = 0; i < 10; i++) {
        this.upload(i, this.selectedFiles[i]);
      }
     
    }
  }

  close() {
    this.dialog.close({ event: 'closed' , data: this.uploadedImages})
  }

}
