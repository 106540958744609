import { Component, OnInit } from '@angular/core';
import { urlConstant } from '../../constant/urlConstant';
import { CommonService } from '../../common/commonService';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-data-migration',
  templateUrl: './data-migration.component.html',
  styleUrls: ['./data-migration.component.scss']
})
export class DataMigrationComponent implements OnInit {

  constructor(
    public _CommonService: CommonService,
    public toasterService: ToasterService,
  ) { }

  ngOnInit() {
  }

  uploadDataFromExcel(){
    this._CommonService.post(urlConstant.BillUtility.addBillFromExcelData,null).subscribe(res => {
      if (!!res) {
        this.toasterService.pop('success', 'Success', res.message);
      } else {
        this.toasterService.pop('error', 'Error', res.message);
      }
    }, (error) => {
      if (error != null) {
        this.toasterService.pop('error', 'Error', error.message);
      }
    });
 }

}
