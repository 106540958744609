import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { CommonService } from '../../../common/commonService';
import { isAlphabet } from '../../../common/custom.validator';
import { urlConstant } from '../../../constant/urlConstant';
import { RentPaymentService } from '../../../services/rent-payment.service';

@Component({
  selector: 'app-share-user-details',
  templateUrl: './share-user-details.component.html',
  styleUrls: ['./share-user-details.component.scss']
})
export class ShareUserDetailsComponent implements OnInit {
  shareForm: any;
  sid: string;
  userData: any;
  show: boolean = false;
 

  constructor( public formBuilder: FormBuilder,
    public _CommonService: CommonService,
    public toasterService: ToasterService,
    public dialogRef: MatDialogRef<ShareUserDetailsComponent>,
    public router: Router,
    public route: ActivatedRoute,
    private rentPaymentService: RentPaymentService) { 
      this.createForm();
      this.sid = this.route.snapshot.queryParamMap.get('sid');
    }

  ngOnInit() {
  
  }

  createForm() {
    this.shareForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required, isAlphabet]),
      lastName: new FormControl('', [Validators.required, isAlphabet]),
      email: new FormControl('',[Validators.required,Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)] ),
      mobileNo: new FormControl('',[Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    });
  }

  send() {
    
    const shareFormData = {
      email: this.shareForm.value.email,
      mobileNo: this.shareForm.value.mobileNo ,
      firstName: this.shareForm.value.firstName,
      lastName: this.shareForm.value.lastName
    };
    this._CommonService.showLoading();
    this._CommonService.post(urlConstant.Property.saveShareUserDetails, shareFormData).subscribe(res => {
      this._CommonService.hideLoading();
      if (res.Status === '200') {
        this.userData = res.data.User;
        this.toasterService.pop('success', 'Success', res.message);
        localStorage.setItem("userData", JSON.stringify(this.userData));
        this.router.navigate(['/property/search/schedule-tour'], { queryParams: { sid: this.sid } });
        this.dialogRef.close();
      } 
    });
  }
  
}
