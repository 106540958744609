import { Component } from '@angular/core';
import { BaseComponent } from '../../../common/base.component';
import { CmsService } from '../../../common/cms.service';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent extends BaseComponent {

  constructor(cms: CmsService) {
    super(cms);
  }

  ngOnInit() {

  }
}
