import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToasterService} from 'angular2-toaster';
import Swal from 'sweetalert2';
import {CommonService} from '../../common/commonService';
import {isNumber} from '../../common/custom.validator';
import {urlConstant} from '../../constant/urlConstant';
import {Globals} from '../../globals';
import {isAlphabet, isValidEmail, MustMatch} from '../../shared/ngbd-modal-content/validators/custom.validator';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material';
import {BaseComponent} from '../../common/base.component';
import {CmsService} from '../../common/cms.service';
import {LegalDocService} from '../../shared/legal-doc/legal-doc.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent extends BaseComponent {

  signUpForm: FormGroup;
  invitationId: any;
  userDetail: any;
  isDisabled: any = false;

  constructor(
    public _CommonService: CommonService,
    public toasterService: ToasterService,
    public formBuilder: FormBuilder,
    public router: Router,
    cms: CmsService,
    private route: ActivatedRoute,
    private legalDocService: LegalDocService,
    public dialog: MatDialog,
  ) {
    super(cms);
    this.createForm();

    this.route.queryParams.subscribe(params => {

      this.invitationId = params['invitationId'];

    });
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.invitationId) {
      this.getUserDetailByInvitationId();
      this.isDisabled = true;
    }
  }

  openDoc(title, doc) {
    this.legalDocService.openDialog({title: title, doc: doc});
  }

  createForm(): void {
    this.signUpForm = this.formBuilder.group({
      'firstName': new FormControl('', [Validators.required, isAlphabet]),
      'lastName': new FormControl('', [Validators.required, isAlphabet]),
      'email': new FormControl('', [Validators.required, isValidEmail]),
      'number': new FormControl('', [Validators.required, isNumber, Validators.minLength(10), Validators.maxLength(10)]),
      'password': new FormControl('', [Validators.required, Validators.minLength(8)]),
      'roleId': new FormControl('', [Validators.required]),
      'confirmPassword': new FormControl('', [Validators.required]),
      'isTermAccepted': new FormControl(false),
      'isClicked': new FormControl(false),
      'isSubmited': new FormControl(false),
      'onlySelf': new FormControl(''),
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  get signUpFormIsClicked(): FormControl {
    return this.signUpForm.get('isClicked') as FormControl;
  }

  get signUpFormIsSubmmited(): FormControl {
    return this.signUpForm.get('isSubmited') as FormControl;
  }

  signUpFormSubmit(): void {
    this.signUpFormIsClicked.setValue(true);

    if (this.signUpForm.invalid && this.signUpFormIsSubmmited.value === false) {
      return;
    } else {
      if (this.signUpForm.get('isTermAccepted').value === false) {
        this.toasterService.pop('error', 'Error', 'Please accept terms of use and privacy policy');
        return;
      }

      this.signUpFormIsSubmmited.setValue(true);
      this._CommonService.showLoading();

      const signUpData = this.signUpForm.value;
      signUpData['userName'] = signUpData['email'];
      signUpData['createdBy'] = 'y';
      const reqData = {
        createdBy: signUpData.createdBy,
        email: signUpData.email,
        firstName: signUpData.firstName,
        lastName: signUpData.lastName,
        number: signUpData.number,
        password: signUpData.password,
        roleId: signUpData.roleId,
        username: signUpData.userName,
        inivitationId: this.invitationId != null ? this.invitationId : null
      };
      this._CommonService.post(urlConstant.Auth.Registration, JSON.stringify(reqData)).subscribe((res) => {
        if (res && res['Status'] === '200') {
          const message = !!res.data.UserDetails.enabled ? 'Your account has been setup successfully.' : 'Please check your mail for account activation email.';

          const dialogRef = this.dialog.open(ConfirmDialogComponent, { data : {disableClose: true, message: message} });
          dialogRef.afterClosed().subscribe(result => {
            this.router.navigate(['/login']);
          });
        } else {
          this.toasterService.pop('error', 'Error', res.message);
        }
      }, (error) => {
        if (error != null) {
          this.toasterService.pop('error', 'Error', error.message);
        }
      }).add(() => {
        this.signUpFormIsClicked.setValue(false);
        this.signUpFormIsSubmmited.setValue(false);
        this._CommonService.hideLoading();
      });
    }
  }

  isFormSubmittedAndError(controlName: string, errorName: string = '', notError: Array<string> = new Array()): any {
    return Globals.isFormSubmittedAndError(this.signUpForm, this.signUpForm.get('isClicked').value, controlName, errorName, notError);
  }

  getUserDetailByInvitationId() {
    this._CommonService.showLoading();
    this._CommonService.get<any>(urlConstant.Property.getPropertyUserInvitationByInvitationId + '?invitationId=' + this.invitationId).subscribe(res => {
      if (res.Status === '200') {
        this.userDetail = res.data.PropertyUserInvitation;
        if (this.userDetail) {
          if (this.userDetail.userRole === 'Tanants') {
            this.userDetail.roleId = '333';
          } else if (this.userDetail.userRole === 'Property Manager') {
            this.userDetail.roleId = '222';
          } else if (this.userDetail.userRole === 'Owner') {
            this.userDetail.roleId = '111';
          }

        }
        this.setFormValue();
      } else {
        this.toasterService.pop('error', 'Error', res.message);
      }
      this._CommonService.hideLoading();
    }, (error) => {
      if (error != null) {
        this.toasterService.pop('error', 'Error', error.message);
      }
      this._CommonService.hideLoading();
    });
  }

  setFormValue() {
    this.signUpForm.controls['firstName'].setValue(this.userDetail.firstName);
    this.signUpForm.controls['lastName'].setValue(this.userDetail.lastName);
    this.signUpForm.controls['email'].setValue(this.userDetail.emailAddress);
    this.signUpForm.controls['number'].setValue(this.userDetail.phoneNumber);
    this.signUpForm.controls['roleId'].setValue('' + this.userDetail.roleId);
    this.signUpForm.controls['onlySelf'].setValue('' + this.userDetail.roleId);
  }
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: 'confirm.html',
})
export class ConfirmDialogComponent {
  message = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message;
  }
}
