import { Component, OnInit } from '@angular/core';
import { dataConstant } from '../../constant/dataConstant';
import { CommonService } from '../../common/commonService';
import { urlConstant } from '../../constant/urlConstant';
import { ToasterService } from 'angular2-toaster';

import { Router } from '@angular/router';
import {BaseComponent} from '../../common/base.component';
import {CmsService} from '../../common/cms.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends BaseComponent {

  loginuserdetail: any;
  authenticationData: any = {};
  userData: any = {};
  emailPattren = dataConstant.EmailPattren;
  isSubmit = false;

  constructor(public _commonService: CommonService,
              public toasterService: ToasterService,
              cms: CmsService,
              public router: Router) {
    super(cms);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  login() {
    this.router.navigate(['/login']);
  }

  forgotPassword(forgotPasswordForm) {
    this.isSubmit = true;
    if (forgotPasswordForm.form.invalid) {
      return;
    } else {
      this._commonService.showLoading();
      this._commonService.post(urlConstant.Auth.ForgetPassword + "?mailId="+this.authenticationData.mailId, null)
        .subscribe((response: any) => {
          this._commonService.hideLoading();
          if (response && response.Status === '200') {
            this.toasterService.pop('success', 'success', response.message);
            this.isSubmit = false;
          } else {
            this.isSubmit = false;
            this.toasterService.pop('error', 'Error', response.message);
          }

          this.authenticationData = {};

        }, error => {
          this.isSubmit = false;
          this._commonService.hideLoading();
          if (error != null) {
            this.toasterService.pop('error', 'Error', error.message);
          }
        });
    }
  }
}
