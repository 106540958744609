import { data } from 'jquery';
import { ViewportScroller } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CarouselConfig } from "ngx-bootstrap/carousel";
import { CommonService } from '../../../common/commonService';
import { dataConstant } from '../../../constant/dataConstant';
import { urlConstant } from '../../../constant/urlConstant';
import { SwiperOptions } from 'swiper';
import { SearchContactusKochiComponent } from '../search-contactus-kochi/search-contactus-kochi.component';

@Component({
  selector: 'app-property-website',
  templateUrl: './property-website.component.html',
  styleUrls: ['./property-website.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 1500, noPause: false } },
  ],
})
export class PropertyWebsiteComponent implements OnInit {


  partnerId: any;
  type: any;
  propertyType: any;
  fromPrice: any;
  toPrice: any;
  city: any;
  bhk: any;
  noProperty: boolean = false;
  propertyData: any = [];
  property: any;
  propertyAmenitiesList: {}[];
  additionalPropertyDetailsList: any = [];
  propertyDecription = "";
  propertyDesc: any;
  description: any = [];
  propertyAmenities: any = [];
  anio: number = new Date().getFullYear();
  filterValues: any[] = [];

  propertiesType: any[] = [
    "House/Villa",
    "Commercial Property",
    "Office",
    "Retail",
    "Storage",
    "Hospitality",
    "Apartment",
    "Studio",
    "Showroom",
    "Warehouses",
    "Plot & Farmhouse",
    "Builder Multi Unit",
    "Paying Guest"
  ];


  budget: any[] = [
    "0 -- 5K",
    "5K -- 10K",
    "10K -- 15K",
    "15K -- 20K",
    "20K -- 30K",
    "30K -- 40K",
    "40K -- 50K",
    "50K -- 60K",
    "60K -- 70K",
    "70K -- 80K",
    "80K -- 90K",
    "90K -- 1Lakh",
    "1Lakh -- 5Lakh",
    "5Lakh -- 10Lakh",
    "10Lakh -- 20Lakh",
    "20Lakh -- 30Lakh",
    "30Lakh -- 40Lakh",
    "40Lakh -- 50Lakh",
    "50Lakh -- 75Lakh",
    "75Lakh -- 1Crore",
    "1Crore -- 3Crore",
    "3Crore -- 5Crore",
  ];

  bhkList: any[] = [
    "1BHK",
    "2BHK",
    "3BHK",
    "4BHK"
  ]

  minValue: number = 0;
  maxValue: number = 50000;
  minSelectValue: any;
  maxSelectValue: any;
  @ViewChild("largeModal", { static: false }) public largeModal: ModalDirective;
  sliceEndPoint: any;
  sliceStartPoint: any;
  budgetStartPoint: any;
  budgetEndPoint: any;
  localityList: any[] = [];
  localityStartPoint: any;
  localityEndPoint: any;
  selectedType: string = "";
  propertyDataBackUp: any;
  selectedLocality: string = "";
  filterTypeValues: any[] = [];
  filterLocalityValues: any[] = [];
  checkedType: boolean = false;
  checkedLocality: boolean = false;
  selectedBhk: string = "";
  filterPropertyData: any[] = [];
  priceList: any[];
  largest: number = 0;
  kvalue: number = 0;
  lValue: number = 0;
  cValue: number = 0;
  min: any;
  max: any;
  tempPropertyArray: any[] = [];
  filterBhkValues: any[] = [];
  tempLocalityArray: any[] = [];
  filterBudgetProperty: any[] = [];
  newBudgetPropertyList: any[] = [];
  propertyManagerDetails = [];
  dsp = [];
  images: any[] = [];
  showLargeModal: boolean = false;
  imageProperty: any;
  imageList: any[] = [];
  imageLists: any = [];
  coveredArea: any = [];
  math = Math;
  propertyDescriptions: any[] = [];
  descriptionName: any;
  projectName: any;
  projectNameList: any[] = [];
  floorName: any;
  floorNameList: any[] = [];
  coveredAreaName: any;
  coveredAreaList: any[] = [];
  carpetAreaName: any;
  carpetAreaList: any[] = [];
  flooringTypeName: any;
  flooringTypeList: any[] = [];
  nearByInformationName: any;
  nearByInformationList: any[] = [];
  facingName: any;
  facingList: any[] = [];
  numberOfParkingName: any;
  numberOfParkingist: any[] = [];
  parkingNumberName: any;
  parkingNumberList: any[] = [];
  allotedParkingName: any;
  allotedParkingList: any[] = [];
  ageOfPropertyName: any;
  ageOfPropertyList: any[] = [];
  familyName: any;
  familyNameList: any[] = [];
  menName: any;
  menNameList: any[] = [];
  singleWomenName: any;
  singleWomenNameList: any[] = [];

  constructor(
    public commonService: CommonService,
    public router: Router,
    private route: ActivatedRoute,
    private scroller: ViewportScroller,
    public toasterService: ToasterService,
    public dialog: MatDialog
  ) {
    this.type = this.route.snapshot.queryParamMap.get('type');
    this.propertyType = this.route.snapshot.queryParamMap.get('propertyType');
    this.fromPrice = this.route.snapshot.queryParamMap.get('fromPrice');
    this.toPrice = this.route.snapshot.queryParamMap.get('toPrice');
    this.city = this.route.snapshot.queryParamMap.get('city');
    this.bhk = this.route.snapshot.queryParamMap.get('bhk');
  }

  config: SwiperOptions = {
    // pagination: { 
    //   el: '.swiper-pagination', 
    //   clickable: true 
    // },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    autoplay: {
      delay: 2000,
      disableOnInteraction: false
    },
    spaceBetween: 0,
    init: true,
    enabled: true,
    zoom: {
      maxRatio: 5,
    }

  }

  ngOnInit() {
    this.commonService.showLoading();
    this.getPropertyList();

    this.sliceStartPoint = 0
    this.sliceEndPoint = 4;
    this.localityStartPoint = 0;
    this.localityEndPoint = 4;
  }

  ngAfterViewInit() {
    $('#pdetail').addClass('active');
  }
  goDownDetail() {
    $('#pdetail').addClass('active');
    $('#pdescription').removeClass('active');
    $('#pdetails').removeClass('active');
    $('#pamenities').removeClass('active');
    $('#proom').removeClass('active')
    this.scroller.scrollToAnchor("detail");
  }
  goDownRoom() {
    $('#pdetail').removeClass('active');
    $('#pdescription').removeClass('active');
    $('#pdetails').removeClass('active');
    $('#pamenities').removeClass('active');
    $('#proom').addClass('active')
    this.scroller.scrollToAnchor("proomscroll");
  }
  goDownDescription() {
    $('#pdetail').removeClass('active');
    $('#pdescription').addClass('active');
    $('#pdetails').removeClass('active');
    $('#pamenities').removeClass('active');
    $('#proom').removeClass('active')
    this.scroller.scrollToAnchor("description");
  }
  goDownDetails() {
    $('#pdetail').removeClass('active');
    $('#pdescription').removeClass('active');
    $('#pdetails').addClass('active');
    $('#pamenities').removeClass('active');
    $('#proom').removeClass('active')
    this.scroller.scrollToAnchor("details");
  }
  goDownAmenities() {
    $('#pdetail').removeClass('active');
    $('#pdescription').removeClass('active');
    $('#pdetails').removeClass('active');
    $('#pamenities').addClass('active');
    $('#proom').removeClass('active')
    this.scroller.scrollToAnchor("amenities");
  }

  getPropertyList() {
    const url = urlConstant.Property.searchPropertiesForWebsite + '?type=' + this.type +
      '&propertyType=' + this.propertyType + '&fromPrice=' + this.fromPrice + '&toPrice=' + this.toPrice +
      '&city=' + this.city + '&bhk=' + this.bhk;
    this.commonService.get<any>(url).subscribe((res) => {
      if (res.Status === "200") {
        this.noProperty = false;
        this.propertyData = res.data.property;
        this.propertyDataBackUp = this.propertyData;
        this.images = res.data.images;
        if (this.propertyData.length > 0) {
          for (let i = 0; i < this.propertyData.length; i++) {
            if (this.propertyData[i].type.type === 'Sell' || this.propertyData[i].type.type === 'Sold') {
              this.propertyData[i].price = this.getformat(this.propertyData[i].price);
            }else if (this.propertyData[i].type.type === 'Rent-available' || this.propertyData[i].type.type === 'Rent-Occupied') {
              this.propertyData[i].monthlyRent = this.getformat(this.propertyData[i].monthlyRent);
              this.propertyData[i].securityDeposit = this.getformat(this.propertyData[i].securityDeposit);
            }

            this.propertyData[i]["images"] = [
              {
                filePath: !this.propertyData[i].coverImage
                  ? "/assets/img/Properties/6.jpg"
                  : this.propertyData[i].coverImage,
              },
            ];
            (this.images[this.propertyData[i].propertyId]
              ? this.images[this.propertyData[i].propertyId]
              : []).map((img) => this.propertyData[i].images.push(img));
          }

          this.localityList = [];
          for (let p of this.propertyData) {
            if (this.localityList.indexOf(p.locality) == -1) {
              this.localityList.push(p.locality)
            }
          }
          this.getPropertyDetails(res.data.property[0]);
          this.getAdditionalPropertiesDetails(this.propertyData);
        } else {
          this.noProperty = true;
        }
      } else if (res.Status === "404") {
        this.noProperty = true;
        this.commonService.hideLoading();
      }
    })
  }

  seeAllList() {
    this.sliceEndPoint = this.propertiesType.length - 1;
  }

  seeTrimList() {
    this.sliceEndPoint = 4;
  }

  seeAllLocalityList() {
    this.localityEndPoint = this.localityList.length - 1;
  }

  seeTrimLocalityList() {
    this.localityEndPoint = 4;
  }

  onChangeMinValue(minValue) {
    this.minValue = minValue;
  }

  onChangeMaxValue(maxValue) {
    this.maxValue = maxValue;
  }

  onSliderChange(selectedValues) {
    this.minSelectValue = selectedValues[0];
    this.maxSelectValue = selectedValues[1];
  }

  onSelectedType(event) {
    this.selectedType = event.source.value;
    if (event.checked === true) {
      if (this.filterTypeValues.length > 0) {
        let isFound = this.filterTypeValues.some((ele) => {
          if (ele === event.source.value) {
            return true;
          } else {
            return false
          }
        })
        if (isFound === false) {
          this.filterTypeValues.push(event.source.value);
          this.filterType();
        }
      } else {
        this.filterTypeValues.push(event.source.value);
        this.filterType();
      }
    } else {
      let index = this.filterTypeValues.indexOf(event.source.value);
      if (index !== -1) {
        this.filterTypeValues.splice(index, 1);
        this.removeType();
      }

    }
  }

  onButtonFilterPrice() {
    this.searchBudgetFilter(this.minSelectValue, this.maxSelectValue);
  }

  onSelectedLocality(event) {
    this.selectedLocality = event.source.value;
    if (event.checked === true) {
      if (this.filterLocalityValues.length > 0) {
        let isFound = this.filterLocalityValues.some((ele) => {
          if (ele === event.source.value) {
            return true;
          } else {
            return false
          }
        })
        if (isFound === false) {
          this.filterLocalityValues.push(event.source.value);
          this.filterLocality();
        }
      } else {
        this.filterLocalityValues.push(event.source.value);
        this.filterLocality();
      }

    } else {
      let index = this.filterLocalityValues.indexOf(event.source.value);
      if (index !== -1) {
        this.filterLocalityValues.splice(index, 1);
        this.removeLocality();
      }
    }
  }

  filterType() {
    if (this.tempLocalityArray.length > 0) {
      for (let t = 0; t < 1; t++) {
        for (let i = 0; i < this.tempLocalityArray.length; i++) {
          if (this.tempLocalityArray[i].propertyType === this.selectedType) {
            this.tempPropertyArray.push(this.tempLocalityArray[i]);
          }
        }
      }
    } else {
      for (let t = 0; t < 1; t++) {
        for (let i = 0; i < this.propertyDataBackUp.length; i++) {
          if (this.propertyDataBackUp[i].propertyType === this.selectedType) {
            this.tempPropertyArray.push(this.propertyDataBackUp[i]);
          }
        }
      }
    }

    if (this.tempPropertyArray.length > 0) {
      this.propertyData = this.tempPropertyArray;
    } else {
      this.propertyData = []
      this.noProperty = true;
    }
  }

  searchBudgetFilter(minFilterValue, maxFilterValue) {
    this.filterBudgetProperty = [];
    this.newBudgetPropertyList = [];
    if (this.tempPropertyArray.length > 0) {
      this.filterBudgetProperty = this.tempPropertyArray;
    } else if (this.tempLocalityArray.length > 0) {
      this.filterBudgetProperty = this.tempLocalityArray;
    } else {
      this.filterBudgetProperty = this.propertyDataBackUp;
    }

    for (let i = 0; i < this.filterBudgetProperty.length; i++) {
      if (this.filterBudgetProperty[i].type.type === 'Rent-available' || this.filterBudgetProperty[i].type.type === 'Rent-Occupied') {
        this.filterBudgetProperty[i].rentPrice = this.convertFormat(this.filterBudgetProperty[i].monthlyRent);
        if (this.filterBudgetProperty[i].rentPrice >= Number(minFilterValue) && this.filterBudgetProperty[i].rentPrice <= Number(maxFilterValue)) {
          this.newBudgetPropertyList.push(this.filterBudgetProperty[i]);
        }
      }
    }

    if (this.newBudgetPropertyList.length > 0) {
      this.noProperty = false;
      this.propertyData = this.newBudgetPropertyList;
    } else {
      this.noProperty = true;
      this.propertyData = []
    }
  }


  filterLocality() {
    if (this.tempPropertyArray.length > 0) {
      for (let t = 0; t < 1; t++) {
        for (let i = 0; i < this.tempPropertyArray.length; i++) {
          if (this.tempPropertyArray[i].locality === this.selectedLocality) {
            this.tempLocalityArray.push(this.tempPropertyArray[i]);
          }
        }
      }
    } else {
      for (let t = 0; t < 1; t++) {
        for (let a = 0; a < this.propertyDataBackUp.length; a++) {
          if (this.propertyDataBackUp[a].locality === this.selectedLocality) {
            this.tempLocalityArray.push(this.propertyDataBackUp[a]);
          }
        }
      }
    }

    if (this.tempLocalityArray.length > 0) {
      this.propertyData = this.tempLocalityArray;
    } else {
      this.noProperty = true;
      this.propertyData = []
    }
  }

  removeType() {
    let typeLength = this.tempPropertyArray.length;
    if (this.tempPropertyArray.length > 0) {
      if (this.filterTypeValues.length > 0) {
        for (let t = 0; t < 1; t++) {
          for (let b = 0; b <= typeLength; b++) {
            if (this.tempPropertyArray[b].propertyType === this.selectedType) {
              this.tempPropertyArray.splice(b, 1);
              b--;
            }
          }
        }

        if (this.tempPropertyArray.length > 0) {
          this.propertyData = this.tempPropertyArray;
        }
      } else if (this.tempLocalityArray.length > 0) {
        this.propertyData = this.tempLocalityArray;

      } else {
        let length = this.tempPropertyArray.length
        this.tempPropertyArray.splice(0, length)

        if (this.tempPropertyArray.length > 0) {
          this.propertyData = this.tempPropertyArray
        } else {
          this.propertyData = this.propertyDataBackUp;
        }
      }
    } else if (this.tempLocalityArray.length > 0) {
      this.noProperty = false;
      this.propertyData = this.tempLocalityArray;
    } else {
      this.noProperty = false;
      this.propertyData = this.propertyDataBackUp;
    }
  }

  removeLocality() {
    let lenghtLoclal = this.tempLocalityArray.length;
    if (this.tempLocalityArray.length > 0) {
      if (this.filterLocalityValues.length > 0) {
        for (let l = 0; l < 1; l++) {
          for (let j = 0; j <= lenghtLoclal; j++) {
            if (this.tempLocalityArray[j].locality === this.selectedLocality) {
              this.tempLocalityArray.splice(j, 1);
              j--;
            }
          }
        }

        if (this.tempLocalityArray.length > 0) {
          this.propertyData = this.tempLocalityArray;
        }
      } else if (this.tempPropertyArray.length > 0) {
        this.propertyData = this.tempPropertyArray;
      }
      let lenghtLoclaity = this.tempLocalityArray.length;
      this.tempLocalityArray.splice(0, lenghtLoclaity);


      if (this.tempLocalityArray.length > 0) {
        this.propertyData = this.tempLocalityArray
      } else {
        this.propertyData = this.propertyDataBackUp;
      }

    } else if (this.tempPropertyArray.length > 0) {
      this.noProperty = false;
      this.propertyData = this.tempPropertyArray;
    } else {
      this.noProperty = false;
      this.propertyData = this.propertyDataBackUp;
    }
  }

  searchTypeFilter(selectedType: String) {
    this.propertyData = this.propertyData.filter((el: any) => {
      if (!!selectedType) {
        return el.propertyType === selectedType;
      }
      return true;
    })

  }

  searchLocalityFilter(selectedLocality: String) {
    this.propertyData = this.propertyData.filter((el: any) => {
      if (!!selectedLocality) {
        return el.locality === selectedLocality;
      }
      return true;
    })
  }

  searchBhkFilter(selectedBHK: String) {
    this.propertyData = this.propertyData.filter((el: any) => {
      if (!!selectedBHK) {
        return el.bhk === selectedBHK;
      }
      return true;
    })
  }

  imageModal(property, i) {
    if (property) {
      this.showLargeModal = true;
      this.imageProperty = property;
      this.imageProperty["images"] = [
        {
          filePath: !this.imageProperty.coverImage
            ? "/assets/img/Properties/6.jpg"
            : this.imageProperty.coverImage,
        },
      ];
      (this.images[this.imageProperty.propertyId]
        ? this.images[this.imageProperty.propertyId]
        : []
      ).map((t) => this.imageProperty.images.push(t));
    }
    if (this.imageProperty.images) {
      this.imageLists = this.imageProperty.images
      this.largeModal.show();
    }
  }

  getAdditionalPropertiesDetails(property) {
    for (let prop of property) {
      if (prop.propertyDetails.length === 1) {
        for (let detail of prop.propertyDetails) {
          if (detail.additionalPropertyDetails.length > 0) {
            for (let propDet of detail.additionalPropertyDetails) {
              if (propDet.propertyAddtionalType === 'coveredArea') {
                this.coveredArea.push(propDet);
              }
              if(propDet.propertyAddtionalType === "propertyDescription") {
                this.descriptionName = dataConstant.propertyDetailName[propDet.propertyAddtionalType];
                this.propertyDescriptions.push(propDet);
              }
              if(propDet.propertyAddtionalType === "nearByInformation") {
                this.nearByInformationName = dataConstant.propertyDetailName[propDet.propertyAddtionalType];
                this.nearByInformationList.push(propDet)
              }
            }
          }
        }
      }
    }
  }

  getPropertyDetails(property) {
    $('.address').removeClass('addBorder');
    $('#' + property.id).addClass('addBorder');
    this.propertyData.map((p) => (p["class"] = ""));
    this.property = property;
    this.propertyAmenitiesList = [{}];
    this.property["class"] = "selected-property";
    this.property["images"] = [
      {
        filePath: !this.property.coverImage
          ? "/assets/img/Properties/6.jpg"
          : this.property.coverImage,
      },
    ];

    this.property["rooms"] = [];
    this.property["furnitures"] = [];
    if (this.property.propertyDetails && this.property.propertyDetails.length === 1) {
      const propertyDetails = this.property.propertyDetails[0];
      if (propertyDetails.additionalPropertyDetails) {
        propertyDetails.additionalPropertyDetails
          .filter(
            (t) =>
              t.propertyAddtionalKey === "Furnished" &&
              t.propertyAddtionalValue !== "0"
          )
          .map((t) => this.property.furnitures.push(t));
      }
      if (propertyDetails.kitchen) {
        this.property.rooms.push({
          propertyAddtionalType: "Kitchen",
          propertyAddtionalValue: propertyDetails.kitchen,
        });
      }
      if (propertyDetails.bedRoom) {
        this.property.rooms.push({
          propertyAddtionalType: "BedRoom",
          propertyAddtionalValue: propertyDetails.bedRoom,
        });
      }
      if (propertyDetails.bathRoom) {
        this.property.rooms.push({
          propertyAddtionalType: "Bath room",
          propertyAddtionalValue: propertyDetails.bathRoom,
        });
      }
      if (propertyDetails.livingRoom) {
        this.property.rooms.push({
          propertyAddtionalType: "Living room",
          propertyAddtionalValue: propertyDetails.livingRoom,
        });
      }

      if (propertyDetails.additionalPropertyDetails) {
        propertyDetails.additionalPropertyDetails
          .filter(
            (t) =>
              t.propertyAddtionalKey === "additionalRoom" &&
              t.propertyAddtionalValue !== "0"
          )
          .map((t) => this.property.rooms.push(t));

        this.additionalPropertyDetailsList =
          propertyDetails.additionalPropertyDetails.filter(
            (x) => x.propertyAddtionalKey === "propertyDetail"
          );
      }
      this.additionalPropertyDetailsList.filter((item, index) => {
        this.propertyDecription = "";
        if (item.propertyAddtionalType === "propertyDescription") {
          this.propertyDecription = item.propertyAddtionalValue;
        }
      });

      this.propertyDesc = this.additionalPropertyDetailsList.filter((item, index) => {
        if (item.propertyAddtionalType === "propertyDescription") {
          return item;
        }
      });

      if (!this.propertyDesc.length) {
        console.log("this array is empty");
      } else if (this.propertyDesc.length > 0) {
        this.description = this.propertyDesc[0].propertyAddtionalValue;
      }

      var propertyAmenities = [];
      propertyDetails.propertyAmenities.filter((item) => {
        if (item.propertyAmenitiesKey == "propertyAmenities") {
          this.propertyDecription = item.propertyAmenitiesType;
        }

        if (item.propertyAmenitiesValue == "true" && !propertyAmenities.includes(item.propertyAmenitiesType)) {
          propertyAmenities.push(item.propertyAmenitiesType);
          this.propertyAmenitiesList.push(item);
        }
      });

      this.propertyAmenities = propertyDetails.propertyAmenities;
      if (this.propertyAmenities.propertyAmenitiesValue === 'true') {
        console.log("a", this.propertyAmenities)
      }
    }
    this.commonService.hideLoading();
  }

  getPropertyDetailNames(key) {
    return dataConstant.propertyDetailName[key] ? dataConstant.propertyDetailName[key] : key;
  }
  

  contactOwner(property) {
    const dialogRef = this.dialog.open(SearchContactusKochiComponent, {
      maxWidth: '400px',
      data: {
        propertyName: property
      },
      autoFocus: false
    })

    dialogRef.afterClosed().subscribe(res => { });

  }

  getformat(number) {
    if (number == 0) {
      return 0;
    }
    else {
      if (number <= 999) {
        return number;
      }
      else if (number >= 10000000 && number <= 9999999999) {
        return (number / 10000000) + 'Cr';
      }
      else if (number >= 100000 && number <= 99999999) {
        return (number / 100000) + 'Lacs';
      }
      else if (number >= 1000 && number <= 999999) {
        return (number / 1000) + 'K';
      }
      else
        return number;
    }
  }

  convertFormat(value) {
    let regex = new RegExp('([0-9]+)|([a-zA-Z]+)', 'g');
    let num;
    if (value !== null && value !== 0) {
      if (value.includes("K")) {
        let splittedArray = value.match(regex);
        if(splittedArray.length > 2) {
          num = Number(splittedArray[0]+'.'+ splittedArray[1]).toFixed(2);
        }else {
          num = splittedArray[0];
        }
        return num * 1000;
      } else if (value.includes("Lacs")) {
        let numLacs;
        let splittedArrayLacs = value.match(regex);
        if(splittedArrayLacs.length > 2) {
          numLacs = Number(splittedArrayLacs[0]+'.'+ splittedArrayLacs[1]).toFixed(2);
        }else {
          numLacs = splittedArrayLacs[0];
        }
        return numLacs * 100000;
      } else if (value.includes("Cr")) {
        let numCr;
        let splittedArrayCr = value.match(regex);
        if(splittedArrayCr.length > 2) {
          numCr = Number(splittedArrayCr[0]+'.'+ splittedArrayCr[1]).toFixed(2);
        }else {
          numCr = splittedArrayCr[0];
        }
        return numCr * 10000000;
      }
    }
  }
}