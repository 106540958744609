import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buying-property',
  templateUrl: './buying-property.component.html',
  styleUrls: ['./buying-property.component.scss']
})
export class BuyingPropertyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
