
import { Component, OnInit, ViewChild } from "@angular/core";
import { CommonService } from "../../common/commonService";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { ToasterService } from "angular2-toaster";
import { urlConstant } from "../../constant/urlConstant";
import { CarouselConfig } from "ngx-bootstrap/carousel";
import { BsModalService, ModalDirective } from "ngx-bootstrap/modal";
import { NgForm } from "@angular/forms";
import { dataConstant, VideoExt } from "../../constant/dataConstant";
import { ViewportScroller } from "@angular/common";
import { MatDialog } from "@angular/material";
import { MobileScheduleTourComponent } from "./mobile-schedule-tour/mobile-schedule-tour.component";
import { ShareUserDetailsComponent } from "./share-user-details/share-user-details.component";
import { SwiperOptions } from 'swiper';
import { NgbCarousel } from "@ng-bootstrap/ng-bootstrap";

declare var $: any;
@Component({
  selector: "app-property-search",
  templateUrl: "./property-search.component.html",
  styleUrls: ["./property-search.component.scss"],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 1500, noPause: false } },
  ],
})
export class PropertySearchComponent implements OnInit {
  currentDate: Date = new Date();
  public selectedWeekDates: Date[] = new Array(10).fill(new Date());
  public selectedWeek: Date = new Date();
  nextDay: Date;
  nextDay1: Date;
  properties = [];
  propertyManagerDetails = [];
  additionalPropertyDetailsList = [];
  alladditionalPropertyDetailsList = [];
  propertyAmenities = [];
  propertyAmenitiesList = [];
  contactUsModel: any = {};
  propertyDecription = "";
  isSubmitted = false;
  dsp = [];
  property;
  interested = false;
  managerDetail = null;
  pmanagerDetail = {};
  sid: string;
  tempDate: any;
  anio: number = new Date().getFullYear();
  showVar: boolean = false;
  cardShow: boolean= true;
  mainContent: boolean = false;
  visibility: boolean = false;

  @ViewChild("largeModal", { static: false }) public largeModal: ModalDirective;
  shareProperties: any;
  email: void;
  propertyDecriptions: any;
  propertyDesc: any;
  description: any = [];
  pid: any;
  currentProperty: any;
  currentIndex: any;
  currentManager: any;
  coveredArea: any=[];
  houseArea: any;
  bathroom: any;
  bedroom: any;
  allProperty: any = [];
  currentImages: any=[];
  dspData: any=[];
  imagesData: any=[];
  shareUserDetail: any;
  ipAddress: any;
  deviceInfo = null;
  propertyCount: any;
  propertyId: any;
  idslist: any = [];
  event: any=0;
  propertyShareCount: any;
  shareCount: number = 0;
  public browserRefresh: boolean;
  clickedDate: Date;
  lastClickedDate: Date;

  playingVideo: HTMLVideoElement | null = null;

  activeId: string = '0';
  //@ViewChild('carousel', { static: true }) carousel: NgbCarousel;
  @ViewChild('carousel', { static: false }) carousel!: NgbCarousel;
  constructor(
    public _CommonService: CommonService,
    public router: Router,
    private route: ActivatedRoute,
    private scroller: ViewportScroller,
    public toasterService: ToasterService,
    public dialog: MatDialog,
    private modalService: BsModalService
  ) {
    this.sid = this.route.snapshot.queryParamMap.get('sid');
    this.route.queryParams.subscribe((params) => {
      if (!!params["sid"]) {
        this.getAllProperty(params["sid"]);
      }
    });
  }

  // config: SwiperOptions = {
  //   pagination: { 
  //     el: '.swiper-pagination', 
  //     clickable: true 
  //   },
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev'
  //   },
  //   spaceBetween: 30,
  //   init: true,
  //   enabled: true,
  // };

  ngOnInit() {  
    console.log('hello from the search')
    this.nextDay = new Date();
    this.nextDay.setDate(this.nextDay.getDate() + 1);
    this.nextDay1 = new Date();
    this.nextDay1.setDate(this.nextDay.getDate() + 1);
    this.SetSelectedWeekDates();
    this.visibility = !this.visibility;
    this.getShareUserDetails();
    this.reloadCheck();
  }

  reloadCheck() {
    if (window.performance) {
      console.info("window.performance works fine on this browser");
    }
    console.info(performance.navigation.type);
    if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
     
      console.info( "This page is reloaded" );
    } else {
      this.shareCount++;
      console.info( "This page is not reloaded");
      this.addShareCount(this.sid, this.shareCount);
    }
  }

  addShareCount(sid, shareCount){
    this.lastClickedDate = new Date();
    const data={
      sid: sid,
      shareCount: shareCount,
      lastClickedDate:  this.lastClickedDate 
    }
    this._CommonService.post(urlConstant.Property.addShareCount, data).subscribe((res:any)=>{})
  }

  SetSelectedWeekDates(): void {
    let dte: Date = new Date(this.selectedWeek);
    this.selectedWeekDates[0] = new Date();
    for (let i = 1; i < 10; i++) {
      this.selectedWeekDates[i] = new Date(dte.setDate(dte.getDate() + 1));
    }
  }
  ngAfterViewInit() {
    $('#pdetail').addClass('active');
  }


  goDownDetail() {
    $('#pdetail').addClass('active');
    $('#pdescription').removeClass('active');
    $('#pdetails').removeClass('active');
    $('#pamenities').removeClass('active');
    $('#proom').removeClass('active')
    this.scroller.scrollToAnchor("detail");
  }
  goDownRoom() {
    $('#pdetail').removeClass('active');
    $('#pdescription').removeClass('active');
    $('#pdetails').removeClass('active');
    $('#pamenities').removeClass('active');
    $('#proom').addClass('active')
    this.scroller.scrollToAnchor("proomscroll");
  }
  goDownDescription() {
    $('#pdetail').removeClass('active');
    $('#pdescription').addClass('active');
    $('#pdetails').removeClass('active');
    $('#pamenities').removeClass('active');
    $('#proom').removeClass('active')
    this.scroller.scrollToAnchor("description");
  }
  goDownDetails() {
    $('#pdetail').removeClass('active');
    $('#pdescription').removeClass('active');
    $('#pdetails').addClass('active');
    $('#pamenities').removeClass('active');
    $('#proom').removeClass('active')
    this.scroller.scrollToAnchor("details");
  }
  goDownAmenities() {
    $('#pdetail').removeClass('active');
    $('#pdescription').removeClass('active');
    $('#pdetails').removeClass('active');
    $('#pamenities').addClass('active');
    $('#proom').removeClass('active')
    this.scroller.scrollToAnchor("amenities");
  }
  Oninterested() {
    this.interested = !this.interested;
  }
  getAllProperty(sid) {
    
    this._CommonService.showLoading();
    const url = urlConstant.Property.SearchProperties + "?sid=" + sid;
    this._CommonService.get<any>(url).subscribe(
      (res) => {
        this._CommonService.hideLoading();
        if (res.Status === "200") {
          this.properties = res.data.list;
          this.dspData = res.data.dsp;
          
          for (let i = 0; i < this.properties.length; i++) {
            if (this.properties[i].type.type === 'Sell' || this.properties[i].type.type === 'Sold') {
              this.properties[i].price = this.getformat(this.properties[i].price);
            }
            else if (this.properties[i].type.type === 'Rent-available' || this.properties[i].type.type === 'Rent-Occupied') {
              this.properties[i].monthlyRent = this.getformat(this.properties[i].monthlyRent);
              this.properties[i].securityDeposit = this.getformat(this.properties[i].securityDeposit);
            }
          }
          this.propertyManagerDetails = res.data.pm;
          this.dsp = res.data.dsp;
          if(this.properties !== null && this.properties.length > 0) {
            this.getPropertyDetails(this.event,this.properties[0]);
            this.getAdditionalPropertiesDetails(this.properties);
          }
        }
      },
      (error) => {
        if (error != null) {
          this.toasterService.pop("error", "Error", error.message);
        }
        this._CommonService.hideLoading();
      }
    );
  }

  getAdditionalPropertiesDetails(property) {
    for(let prop of property) {
      if(prop.propertyDetails.length === 1){
        for(let detail of prop.propertyDetails) {
          if(detail.additionalPropertyDetails.length > 0) {
            for(let propDet of detail.additionalPropertyDetails) {
              if(propDet.propertyAddtionalType === 'coveredArea') {
                this.coveredArea.push(propDet);
              }
            }
          }
        }
      }
    }
  }

  showCard(property, i,manager){

    property["images"] = [
      {
        filePath: !property.coverImage
          ? "/assets/img/Properties/6.jpg"
          : property.coverImage,
      },
    ];
    var tempImageList = property.imageList.map(imagePath => {
      return {filePath: imagePath};
    });
    // this.property.imageList.forEach(imagePath => {
    //         var image = {filePath: imagePath};
    //         this.property.images.push(image)
    //       })
    property.images = [...property.images, ...tempImageList];

    this.currentProperty = property
    this.currentIndex = i;
    this.currentManager = manager;
    this.imagesData =  property.images
    this.showVar = !this.showVar
    this.cardShow = !this.cardShow
    this.mainContent = !this.mainContent
    this.currentImages = [...this.currentImages, ...this.currentProperty.imageList];
  }
  getPropertyDetails(event,property) {
  
    this.resetActiveIndex();

    this._CommonService.showLoading();
    this.propertyId = property.propertyId;
    if(event !== 0 && event.isTrusted === true) {
      this.idslist.push(this.propertyId);
      this.propertyCount = {}

      for(let element of this.idslist) {
        if(this.propertyCount[element]) {
          this.propertyCount[element] += 1;
        }else {
          this.propertyCount[element] = 1;
        }
      }
    }
    localStorage.setItem('saveProperties', JSON.stringify(property));
    $('.address').removeClass('addBorder');
    $('#' + property.id).addClass('addBorder');
    this.properties.map((t) => (t["class"] = ""));
    this.property = property;
    this.propertyAmenitiesList = [{}];
    this.property["class"] = "selected-property";
    this.property["images"] = [
      {
        filePath: !this.property.coverImage
          ? "/assets/img/Properties/6.jpg"
          : this.property.coverImage,
      },
    ];
    
    var tempImageList = property.imageList.map(imagePath => {
      return {filePath: imagePath};
    });
    // this.property.imageList.forEach(imagePath => {
    //         var image = {filePath: imagePath};
    //         this.property.images.push(image)
    //       })
    this.property.images = [...property.images, ...tempImageList];
    // (this.dsp[this.property.propertyId]
    //   ? this.dsp[this.property.propertyId]
    //   : []
    // ).map((t) => this.property.images.push(t));
    
    this.property["rooms"] = [];
    this.property["furnitures"] = [];

    if(this.property.propertyDetails.length > 0 && this.property.propertyDetails !== null) {
      const propertyDetails = this.property.propertyDetails[0];
      if (propertyDetails.additionalPropertyDetails) {
        propertyDetails.additionalPropertyDetails
          .filter(
            (t) =>
              t.propertyAddtionalKey === "Furnished" &&
              t.propertyAddtionalValue !== "0"
          )
          .map((t) => this.property.furnitures.push(t));
      }
      if (propertyDetails.livingRoom) {
        this.property.rooms.push({
          propertyAddtionalType: "Living room",
          propertyAddtionalValue: propertyDetails.livingRoom,
        });
      }
      if (propertyDetails.kitchen) {
        this.property.rooms.push({
          propertyAddtionalType: "Kitchen",
          propertyAddtionalValue: propertyDetails.kitchen,
        });
      }
      if (propertyDetails.bedRoom) {
        this.property.rooms.push({
          propertyAddtionalType: "BedRoom",
          propertyAddtionalValue: propertyDetails.bedRoom,
        });
      }
      if (propertyDetails.bathRoom) {
        this.property.rooms.push({
          propertyAddtionalType: "Bath room",
          propertyAddtionalValue: propertyDetails.bathRoom,
        });
      }
      if (propertyDetails.additionalPropertyDetails) {
        propertyDetails.additionalPropertyDetails
          .filter(
            (t) =>
              t.propertyAddtionalKey === "additionalRoom" &&
              t.propertyAddtionalValue !== "0"
          )
          .map((t) => this.property.rooms.push(t));

        this.additionalPropertyDetailsList =
          propertyDetails.additionalPropertyDetails.filter(
            (x) => x.propertyAddtionalKey === "propertyDetail"
          );
      }
      this.additionalPropertyDetailsList.filter((item, index) => {
        this.propertyDecription = "";
          if (item.propertyAddtionalType === "propertyDescription") {
            this.propertyDecription = item.propertyAddtionalValue;
          }
      });
      
      this.propertyDesc = this.additionalPropertyDetailsList.filter((item, index) => {
        if(item.length > 0) {
          if (item.propertyAddtionalType === "propertyDescription") {
            return item;
          }
        }
      });

      if(!this.propertyDesc.length){
      }else if(this.propertyDesc.length > 0) {
        this.description = this.propertyDesc[0].propertyAddtionalValue;
      }
    
      var propertyAmenities = [];
      propertyDetails.propertyAmenities.filter((item) => {
        if (item.propertyAmenitiesKey == "propertyAmenities") {
          this.propertyDecription = item.propertyAmenitiesType;
        }

        if (item.propertyAmenitiesValue == "true" && !propertyAmenities.includes(item.propertyAmenitiesType)) {
          propertyAmenities.push(item.propertyAmenitiesType);
          this.propertyAmenitiesList.push(item);
        }
      });

      this.propertyAmenities = propertyDetails.propertyAmenities;
    }     
    this.setManagerDetails(property);
    this.addPropertyCount(this.propertyCount);
  }

  addPropertyCount(propertyCount) {
    this._CommonService.showLoading();
    if(propertyCount !== undefined) {
      for(let property of this.properties) {
        if(propertyCount[property.propertyId]) {
          this.propertyShareCount = propertyCount;
        }
      }
      const obj = {
        list: this.propertyShareCount,
        shareId: this.sid,
        shareCount: this.shareCount,
      }
      this._CommonService.showLoading();
      this._CommonService.post(urlConstant.Property.addPropertyCount,obj).subscribe((res:any) => {
        if(res.Status === '200') {
          this._CommonService.hideLoading();
        }
      })
    }
  }

  setManagerDetails(property) {
    this.managerDetail = null;
    const managerDetail = this.propertyManagerDetails[property.propertyId];
    if (!managerDetail) {
      return;
    }
    this.managerDetail = managerDetail;
    
  }

  getShareUserDetails(){
    this._CommonService.get<any>(urlConstant.Property.getShareUserDetails + '?sid='+this.sid).subscribe(res => {
      if(res.Status === '200'){
        this.shareUserDetail = res.data.shareUserDetails;
        localStorage.setItem('propertyMangerDetails', JSON.stringify(this.shareUserDetail));
      }
    })
  }

  SendContactUsDetail(contactForm: NgForm) {
    if (contactForm.invalid) {
      this.isSubmitted = true;
      return;
    }
    this.isSubmitted = false;
    this._CommonService.showLoading();
    this._CommonService.post(urlConstant.Auth.addContactUs, this.contactUsModel)
      .subscribe((res) => {
        if (!!res) {
          this.toasterService.pop("success", "Success", res.message);
        } else {
          this.toasterService.pop("error", "Error", res.message);
        }
        contactForm.reset();
        this._CommonService.hideLoading();
      }, (error) => {
        if (error != null) {
          this.toasterService.pop("error", "Error", error.message);
        }
        this._CommonService.hideLoading();
      }
      );
  }

  getPropertyDetailNames(key) {
    return dataConstant.propertyDetailName[key] ? dataConstant.propertyDetailName[key] : key;
  }

  tour() {
    localStorage.setItem('tempDate', JSON.stringify(this.tempDate));
    this.confirm(this.sid, this.managerDetail.email);
  }
  date(currentDate) {
    this.tempDate = currentDate
  }

  confirm(sid, eamil) {
    const dialogRef = this.dialog.open(ShareUserDetailsComponent
    );

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  schedule() {
    const dialogRef = this.dialog.open(MobileScheduleTourComponent, {
      data:this.managerDetail
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  getformat(number) {
    if (number == 0) {
      return 0;
    }
    else {
      if (number <= 999) {
        return number;
      }
      else if (number >= 10000000 && number <= 9999999999) {
        return (number / 10000000) + 'Cr';
      }
      else if (number >= 100000 && number <= 99999999) {
        return (number / 100000) + 'Lacs';
      }
      else if (number >= 1000 && number <= 999999) {
        return (number / 1000) + 'K';
      }
      else
        return number;
    }
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  nextP() {
    this.router.navigate(['/property/search/contact'], { queryParams: { sid: this.sid } });
  }

  // isVideo(path: string) {
  //   const pathLower = path.toLowerCase();
  //   return !!VideoExt.find(ext => pathLower.lastIndexOf(ext) === (pathLower.length - ext.length));
  // }

  isVideo(filePath: string): boolean {
    //console.log('file Path', filePath);
    const videoExtensions = ['.mp4', '.webm', '.ogg']; // Add other video extensions if needed
    return videoExtensions.some(ext => filePath.toLowerCase().endsWith(ext));
  }

  onVideoError(event: Event): void {
    console.error('Video failed to load', event);
  }

  trackByIndex(currentInd: number, item: any): any {
    return 0;
  }

  resetActiveIndex(): void {
    this.activeId = '0';
  }

  carouselInterval = 5000; // Carousel auto-slide interval
  isVideoPlaying = false; // Track whether video is playing

  onVideoPlay(index: number) {
    this.carouselInterval = 50000;
    this.isVideoPlaying = true;
    this.stopCarousel();
  }

  onVideoPause(index: number) {
    this.isVideoPlaying = false;
    this.resumeCarousel();
  }

  stopCarousel() {
    if (this.carousel) {
      this.carousel.pause();
    }
  }

  resumeCarousel() {
    if (this.carousel && !this.isVideoPlaying) {
      this.carousel.cycle();
    }
  }

  onSlide(event: any) {
    if (this.isVideoPlaying) {
      this.stopCarousel();
    } else {
      this.resumeCarousel();    }
  }
}