import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refund-and-cancellation-policy',
  templateUrl: './refund-and-cancellation-policy.component.html',
  styleUrls: ['./refund-and-cancellation-policy.component.scss']
})
export class RefundAndCancellationPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
