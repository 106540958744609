import {Component, Input, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {urlConstant} from '../../../constant/urlConstant';
import {ToasterService} from 'angular2-toaster';
import {CommonService} from '../../../common/commonService';

@Component({
  selector: 'app-contact-and-support',
  templateUrl: './contact-and-support.component.html',
  styleUrls: ['./contact-and-support.component.scss']
})
export class ContactAndSupportComponent implements OnInit {
  @Input() close: () => void;
  contactUsModel: any = {};
  isSubmitted = false;

  constructor(
    public toasterService: ToasterService,
    public _CommonService: CommonService
  ) { }

  ngOnInit() {
  }

  SendContactUsDetail(contactForm: NgForm) {
    if (contactForm.invalid) {
      this.isSubmitted = true;
      return;
    }

    this.isSubmitted = false;
    this._CommonService.showLoading();
    this._CommonService.post(urlConstant.Auth.addContactUs, this.contactUsModel).subscribe((res) => {
      if (!!res) {
        this.toasterService.pop('success', 'Success', res.message);
      } else {
        this.toasterService.pop('error', 'Error', res.message);
      }
      contactForm.reset();
      this._CommonService.hideLoading();
      console.log('this.close');
      if (this.close) {
        this.close();
      }
    }, (error) => {
      if (error != null) {
        this.toasterService.pop('error', 'Error', error.message);
      }
      this._CommonService.hideLoading();
    });


  }
  closed() {
    this.close();
  }
}
