import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../../common/commonService';
import { urlConstant } from '../../../constant/urlConstant';
import { FileUploadService } from '../file-upload.service';
import { MultiFileUploadComponent } from '../multi-file-upload.component';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {
  note: '';
  submitBtnState: boolean=false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<MultiFileUploadComponent>,
  ) {
    this.note = (data && data.note) || '';
  }

  ngOnInit(): void {
  }

  inputHandler(event) {
    this.note = event.target.value
    this.submitBtnState = true
  }

  Submit() {
    this.dialog.close({ note:this.note})
    this.submitBtnState = false
  }

}
