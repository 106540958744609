import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { isNumber, toInteger, padNumber } from '@ng-bootstrap/ng-bootstrap/util/util';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {            
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
  // parse(value: string): NgbDateStruct {
  //   if (value) {
  //     const dateParts = value.trim().split('/');
  //     if (dateParts.length === 1 && isNumber(dateParts[0])) {
  //       return {day: toInteger(dateParts[0]), month: null, year: null};
  //     } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
  //       return {day: toInteger(dateParts[0]), month: toInteger(dateParts[1]), year: null};
  //     } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
  //       return {day: toInteger(dateParts[0]), month: toInteger(dateParts[1]), year: toInteger(dateParts[2])};
  //     }
  //   }
  //   return null;
  // }

  // format(date: NgbDateStruct): string {
  //   return date ?
  //       `${isNumber(date.day) ? padNumber(date.day) : ''}/${isNumber(date.month) ? padNumber(date.month) : ''}/${date.year}` :
  //       '';
  // }
}