import {Injectable} from '@angular/core';
import {CommonService} from '../../common/commonService';
import {HttpEvent} from '@angular/common/http';
import {urlConstant} from '../../constant/urlConstant';
import {Observable} from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(
    private _CommonService: CommonService,
  ) {
  }

  upload(file: File, propertyId: string, userId: string, comment: string, docType: string): Observable<HttpEvent<any>> {
    const formData = new FormData();

    formData.append('comment', comment);
    formData.append('docType', docType);
    formData.append('file', file);
    formData.append('filePath', '');
    formData.append('propertyId', propertyId);
    formData.append('userId', userId);
    return this._CommonService
      .postFormData(
        urlConstant.Property.UploadDocumentPropertyByPropertyId,
        formData
      );
  }
}
