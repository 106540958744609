import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

// Import Containers

import { LoginComponent } from './views/login/login.component';
import { AuthGuard } from './common/auth.guard';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { SignupComponent } from './views/signup/signup.component';
import { AccountAtivationComponent } from './views/account-ativation/account-ativation.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PropertySearchComponent } from './views/property-search/property-search.component';
import { ScheduleTourComponent } from './views/property-search/schedule-tour/schedule-tour.component';
import { PropertyContactComponent } from './views/property-search/property-contact/property-contact.component';
import { ScheduleSuccessComponent } from './views/property-search/schedule-success/schedule-success.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { ApartmentComponent } from './pages/apartment/apartment.component';
import { BuildingComponent } from './pages/building/building.component';
import { BuildersComponent } from './pages/builders/builders.component';
import { BuyingPropertyComponent } from './pages/buying-property/buying-property.component';

import { AboutUsComponent } from './pages/about-us/about-us.component';
import { HouseComponent } from './pages/house/house.component';
import { HousesComponent } from './pages/houses/houses.component';
import { SellPropertyComponent } from './pages/sell-property/sell-property.component';
import { RentalPropertyComponent } from './pages/rental-property/rental-property.component';

import { ConfirmComponent } from './views/property-search/confirm/confirm.component';
import { ContactSuccessComponent } from './views/property-search/contact-success/contact-success.component';
import { MainLandingPageComponent } from './pages/main-landing-page/main-landing-page.component';
import { DisplayPropertyComponent } from './pages/display-property/display-property.component';
import { PropertyWebsiteComponent } from './views/property-search/property-website/property-website.component';
import { DefaultLayoutComponent } from './containers/default-layout/default-layout.component';
import { DataMigrationComponent } from './views/data-migration/data-migration.component';


export const routes: Routes = [
 
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'inspection',
    loadChildren: () => import('./views/unauth-inspection/unauth-inspection.module').then(m => m.UnauthInspectionModule),
  },
  {
    path: 'ticket',
    loadChildren: () => import('./views/unauth-ticket/unauth-ticket.module').then(m => m.UnauthTicketModule),
  },
  {
    path: 'invoice-recipt',
    loadChildren: () => import('./views/unauth-invoice/unauth-invoice.module').then(m => m.UnauthInvoiceModule),
  },
  {
    path:'dynamic-report',
    loadChildren:() => import('./views/unauth-report/unauth-report.module').then(m=> m.UnauthReportModule)
  },
  {
    path: 'display',
    component: DisplayPropertyComponent,
   
  },
  {
    path: 'signup',
    component: SignupComponent,
    data: {
      title: 'Signup Page'
    },
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    },
  },
  {
    path: 'forgotPassword',
    component: ForgotPasswordComponent,
    data: {
      title: 'Forgot Password Page'
    },
  },
  {
    path: 'resetPassword/:token',
    component: ResetPasswordComponent,
    data: {
      title: 'Reset Password Page'
    },
  },
  {
    path: 'activeUser',
    component: AccountAtivationComponent,
    data: {
      title: 'Account Activation'
    },
  },
  {
    path: 'property/search',
    component: PropertySearchComponent,
    data: {
      title: 'Search'
    },
  },
  {
    path: 'property/search/schedule-tour',
    component: ScheduleTourComponent,
    data: {
      title: 'schedule-tour'
    },
  },
  {
    path: 'property/search/contact',
    component: PropertyContactComponent,
    data: {
      title: 'contact'
    },
  },
  {
    path: 'property/search/success',
    component: ScheduleSuccessComponent,
    data: {
      title: 'success'
    },
  },
 
  {
    path: 'property/search/contactSuccess',
    component: ContactSuccessComponent,
    data: {
      title: 'contactSuccess'
    },
  },
  {
    path: 'property/search/propertyWebsite',
    component: PropertyWebsiteComponent,
    data: {
      title: 'propertyWebsite'
    },
  },

  {
    path: 'landingPage',
    component: MainLandingPageComponent,
    
  },
  {
    path: 'about',
    component: AboutUsComponent,
  },
 
  {
    path: 'contact',
    component: ContactUsComponent,
  },
  {
    path: 'house',
    component: HouseComponent,
  },
  {
    path: 'apartment',
    component: ApartmentComponent,
  },
  {
    path: 'houses',
    component: HousesComponent,
  },
  {
    path: 'building',
    component: BuildingComponent,
  },
  {
    path: 'builders',
    component: BuildersComponent,
  },
  {
    path: 'buying_property',
    component:BuyingPropertyComponent,
  },
  {
    path: 'sell_property',
    component: SellPropertyComponent,
  },
  {
    path: 'rental_property',
    component: RentalPropertyComponent,
  },
  {
    path: 'admin',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        data: {
          title: 'Dashboard'
        },
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'task',
        data: {
          title: 'Task'
        },
        loadChildren: () => import('./views/task/task.module').then(m => m.TaskModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'properties',
        data: {
          title: 'Properties'
        },
        loadChildren: () => import('./views/properties/properties.module').then(m => m.PropertiesModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'rentPayment',
        loadChildren: () => import('./views/rent-payment/rent-payment.module').then(m => m.RentPaymentModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'payments',
        loadChildren: () => import('./views/payments/payments.module').then(m => m.PaymentsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'app-inspection',
        loadChildren: () => import('./views/inspection/inspection.module').then(m => m.InspectionModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'billsUtilities',
        loadChildren: () => import('./views/bills-utilities/bills-utilities.module').then(m => m.BillsUtilitiesModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'documents',
        loadChildren: () => import('./views/documents/documents.module').then(m => m.DocumentsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'tenants',
        loadChildren: () => import('./views/tenants/tenants.module').then(m => m.TenantsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'referral',
        loadChildren: () => import('./views/referral/referral.module').then(m => m.ReferralModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'changePassword',
        loadChildren: () => import('./views/change-password/change-password.module').then(m => m.ChangePasswordModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'notificationSetting',
        loadChildren: () => import('./views/notification-setting/notification-setting.module').then(m => m.NotificationSettingModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'subscription',
        loadChildren: () => import('./views/subscription/subscription.module').then(m => m.SubscriptionModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'moveInOut',
        loadChildren: () => import('./views/move-in-out/move-in-out.module').then(m => m.MoveInOutModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'service',
        loadChildren: () => import('./views/service-request/service-request.module').then(m => m.ServiceRequestModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'partner',
        loadChildren: () => import('./views/partner-admin/partner-admin.module').then(m => m.PartnerAdminModule),
        canActivate: [AuthGuard],
      },

      {
        path: 'inquiry',
        loadChildren: () => import('./views/inquiry/inquiry.module').then(m => m.InquiryModule),
        canActivate: [AuthGuard],
      },

      {
        path: 'tenant-information',
        loadChildren: () => import('./views/tenant-information/tenant-information.module').then(m => m.TenantInformationModule),
        canActivate: [AuthGuard],
      },
      {
        path:'report',
        loadChildren: () => import('./views/report/report.module').then(m => m.ReportModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'intake',
        loadChildren: () => import('./views/intake/intake.module').then(m => m.IntakeModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'user-access-control',
        loadChildren: () => import('./views/user-access-control/user-access-control.module').then(m => m.UserAccessControlModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'invoice',
        loadChildren: () => import('./views/invoice/invoice.module').then(m => m.InvoiceModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'data-migration',
        component: DataMigrationComponent,
        data: {
          title: 'Data Migration'
        },
        canActivate: [AuthGuard],
      },
    ]
  },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  entryComponents: [ConfirmComponent]
})


export class AppRoutingModule { }

