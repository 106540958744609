import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { urlConstant } from "../constant/urlConstant";
import { CommonService } from "./commonService";

@Injectable({
    providedIn: 'root'
  })
export class WebsiteCmsService{
  
  public cmsSubject: BehaviorSubject<WebsiteContent>;
  public cmsObservable: Observable<WebsiteContent>;
  property = [];
  
  constructor(private commonService: CommonService) {
    this.cmsSubject = new BehaviorSubject(new WebsiteContent());
    this.cmsObservable = this.cmsSubject.asObservable();
  }

  getService() {
    this.commonService.get<any>(`${urlConstant.CMS.getService}`).subscribe((res) => {
     if (!!res) {
     this.updateServices(res);
     }

    });
  }
  getProperty() {
    this.commonService.get<any>(`${urlConstant.CMS.getProperty}`).subscribe((res) => {
    if (res.status === '200') {
      this.property = res.data.properties;
    }
    });
  }

  updateServices(res) {
    const cms: WebsiteContent = this.cmsSubject.value;
    res.filter(pc => pc.page === 'services').map(pc =>
      pc.contents.map((pcc: any) => {
        switch (pcc.identity) {
          case 'serv_head1':
            cms.servhead1 = pcc.text;
            break;
            case 'serv_body1':
              cms.servbody1 = pcc.text;
              break;
              case 'serv_head2':
              cms.servhead2 = pcc.text;
              break;
              case 'serv_body2':
              cms.servbody2 = pcc.text;
              break;
              case 'serv_head3':
              cms.servhead3 = pcc.text;
              break;
              case 'serv_body3':
              cms.servbody3 = pcc.text;
              break;
              case 'serv_head4':
              cms.servhead4 = pcc.text;
              break;
              case 'serv_body4':
              cms.servbody4 = pcc.text;
              break;
        
        }
      })
    );
    this.cmsSubject.next(JSON.parse(JSON.stringify(cms)));
  }
}

      


export class WebsiteContent {
  servhead1 ='';
  servhead2 ='';
  servhead3 ='';
  servhead4 ='';
  servbody1 ='';
  servbody2 ='';
  servbody3 ='';
  servbody4 ='';
}
// export class property{
//   addressLine1='bhubaneswar'
// }

