import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rental-property',
  templateUrl: './rental-property.component.html',
  styleUrls: ['./rental-property.component.scss']
})
export class RentalPropertyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
