import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../common/base.component';
import { CmsService } from '../../../common/cms.service';

@Component({
  selector: 'app-custom-headers',
  templateUrl: './custom-headers.component.html',
  styleUrls: ['./custom-headers.component.scss']
})
export class CustomHeadersComponent extends BaseComponent {

  constructor(cms: CmsService) {
    super(cms);
   }

}
