import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { filter } from 'rxjs/operators';
import { CommonService } from '../../../common/commonService';
import { urlConstant } from '../../../constant/urlConstant';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  sid: string;
  shareProperties: any = [
    {
      firstName: "",
      lastName: ""
    }
  ];
  email: any;
  constructor(public _CommonService: CommonService,
    public toasterService: ToasterService,
    public router: Router,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<ConfirmComponent>,
  ) { }

  ngOnInit() {
    this.sid = this.route.snapshot.queryParamMap.get('sid');
    this.getSharedData();
  }

  getSharedData() {
    this._CommonService.showLoading();
    this._CommonService.get<any>(urlConstant.Property.getPropertyShare + '?id=' + this.sid).subscribe(res => {
      if (res.Status === '200') {
        this.shareProperties = res.data.user;
      } else {
        this.toasterService.pop('error', 'Error', res.message);
      }
      this._CommonService.hideLoading();
    }, (error) => {
      if (error != null) {
        this.toasterService.pop('error', 'Error', error.message);
      }
      this._CommonService.hideLoading();
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  next() {
    this.router.navigate(['/property/search/schedule-tour'], { queryParams: { sid: this.sid } });
    this.onNoClick();
  }

  nextP() {
    this.router.navigate(['/property/search/contact'], { queryParams: { sid: this.sid } });
    this.onNoClick();
  }
}
